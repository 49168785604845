import ReactDOM from "react-dom";
import PropTypes from "prop-types";

/**
 * Componente che mostra un portal che contiene in children passati nei props.
 * @param {*} param
 */
const Portal = ({ children }) =>
  typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;

Portal.propTypes = {
  /**
   * Children React
   */
  children: PropTypes.object,
};

export default Portal;
