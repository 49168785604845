import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

export const Popover = ({ children, ...props }) => (
  <PopoverPrimitive.Root className="dropdown" {...props}>
    {children}
  </PopoverPrimitive.Root>
);

export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverClose = PopoverPrimitive.Close;


export const PopoverContent = ({ children, ...props }) => (
  <PopoverPrimitive.Content className="popover__content" {...props}>
    {children}
  </PopoverPrimitive.Content>
);
