import React from "react";

function ScrollProgress() {
  const [scrollPercentage, setScrollPercentage] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = React.useCallback(() => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.scrollHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = scrollTop / (docHeight - winHeight);
    setScrollPercentage(Math.round(scrollPercent * 100));
  }, []);

  return (
    <progress
      className="progress progress-primary"
      value={scrollPercentage}
      max="100"
      data-testid="chapter-progress"
    />
  );
}

export default ScrollProgress;
