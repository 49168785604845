import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

UICustomizableOption.propTypes = {
  /**
   * Il titolo del radio group (fungerà da label per il radio group).
   */
  description: PropTypes.string.isRequired,
  /**
   * Il valore che verrà utilizzato come `name` nei children `input[type="radio"]`.
   */
  name: PropTypes.string.isRequired,
  /**
   * I possibili valori che può assumere il radio button.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Il valore che assume il radio group se l'opzione è selezionata.
       */
      val: PropTypes.string.isRequired,
      /**
       * Il contenuto visibile all'utente che identifica un'opzione del
       * radio-group. Accetta una stringa o del JSX per i casi più complessi.
       */
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      /**
       * Una descrizione accessibile dell'opzione.
       */
      title: PropTypes.string.isRequired,
      /**
       * Una stringa di classi da applicare all'opzione.
       * Visto che si tratta di stili specifici per una singola opzione,
       * preferire l'uso delle classi di utilità definite da TailwindCSS.
       */
      style: PropTypes.string,
    }),
  ).isRequired,
  /**
   * L'opzione correntemente attiva del radio-group.
   * Deve corrispondere ad uno dei valori definito in `options[*].val`.
   */
  activeOption: PropTypes.string.isRequired,
  /**
   * La callback chiamata quando viene cambiata la selezione del radio-group.
   */
  onChange: PropTypes.func.isRequired,
};

/**
 * Componente che renderizza un radio group per la selezione di un aspetto del
 * tema. Raccoglie quanto comune a tutte i selettori di opzioni per il tema.
 * @param {*} props - vedi propTypes
 */
function UICustomizableOption(props) {
  const { description, name, options, activeOption, onChange } = props;

  const radioGroupName = `ui-customization-${name}`;

  return (
    <div className="mb-4">
      <p
        id={`${radioGroupName}-label`}
        className="text-xs font-sans font-semibold uppercase mb-1"
      >
        {description}
      </p>
      <div
        role="radiogroup"
        aria-labelledby={`${radioGroupName}-label`}
        className="btn-group grid grid-flow-col"
      >
        {options.map(({ val, content, title, style: optionStyle }) => {
          const btnCls = classNames("btn normal-case", optionStyle, {
            "btn-outline": activeOption !== val,
            "btn-secondary": activeOption === val,
          });
          return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <label key={val} className={btnCls} tabIndex="0">
              <span className="h-full grid place-items-center">{content}</span>
              <input
                className="appearance-none"
                type="radio"
                name={radioGroupName}
                value={val}
                checked={activeOption === val}
                title={title}
                onChange={(evt) => onChange(evt.target.value)}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default UICustomizableOption;
