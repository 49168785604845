import React from "react";
import PropTypes from "prop-types";

export const BACKGROUND_COLORS = {
  primary: "primary",
  secondary: "secondary",
  accent: "accent",
};

const ModifierButton = ({ color, ...props }) => (
  <button
    {...props}
    className={`highlight-box-modifier highlight-box-modifier--${color}`}
  />
);

ModifierButton.propTypes = {
  color: PropTypes.oneOf(Object.keys(BACKGROUND_COLORS)).isRequired,
};

export default ModifierButton;
