/**
 * Data una stringa capisce se è possibile eseguire un cast ad intero.
 * @param {string} a - la stringa da validare
 * @returns {number|null} - l'intero parsato o null in caso di problemi
 */
export function parseAndValidateInterger(a) {
  if (!/^\d+$/.test(a)) {
    return null;
  }
  const res = Number.parseInt(a, 10);
  return Number.isNaN(res) ? null : res;
}
