import { Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { BACKGROUND_COLORS } from "../Blocks/HighlightBox/HighlightBoxModifiers";
import { ELEMENT_TYPES, LIST_BLOCKS } from "../constants";
import { removeMarks, selectBlock, selectEndPath, unwrapNodes } from "./utils";

/**
 * Cambia il blocco in un titolo
 * @param {Object} editor editor di Slate.js
 * @param {Object} element element di Slate.js
 */
export const changeToTitle = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);
  unwrapNodes(editor, ELEMENT_TYPES.glossario);
  unwrapNodes(editor, ELEMENT_TYPES.link);
  removeMarks(editor);

  unwrapNodes(editor, ELEMENT_TYPES.orderedList);
  unwrapNodes(editor, ELEMENT_TYPES.unorderedList);
  unwrapNodes(editor, ELEMENT_TYPES.alphabeticList);
  unwrapNodes(editor, ELEMENT_TYPES.example);

  Transforms.setNodes(editor, { type: ELEMENT_TYPES.heading, weight: 1 });
  selectEndPath(editor, path);
};

/**
 * Cambia il blocco in un paragrafo
 * @param {Object} editor editor di Slate.js
 * @param {Object} element element di Slate.js
 */
export const changeToParagraph = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);

  unwrapNodes(editor, ELEMENT_TYPES.orderedList);
  unwrapNodes(editor, ELEMENT_TYPES.unorderedList);
  unwrapNodes(editor, ELEMENT_TYPES.alphabeticList);
  unwrapNodes(editor, ELEMENT_TYPES.example);

  if (element.type === ELEMENT_TYPES.quote) {
    Transforms.removeNodes(editor, {
      at: path.concat(element.children.length - 1),
    });
    unwrapNodes(editor, ELEMENT_TYPES.quote);
    unwrapNodes(editor, ELEMENT_TYPES.quoteText);
  }

  Transforms.setNodes(editor, { type: ELEMENT_TYPES.paragraph });
  selectEndPath(editor, path);
};

/**
 * Rimuove il highlight box e lascia i componenti dentro
 * @param {Object} editor editor di Slate.js
 * @param {Object} element element di Slate.js
 */
export const removeHighlightBox = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);

  unwrapNodes(editor, ELEMENT_TYPES.highlightBox, path);
};

/**
 * Cambia il blocco in una lista non ordinata
 * @param {Object} editor editor di Slate.js
 * @param {Object} element element di Slate.js
 */
export const changeToBulletedList = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);
  selectBlock(editor, path);

  if (LIST_BLOCKS.includes(element.type)) {
    unwrapNodes(editor, element.type);
  } else {
    if (element.type === ELEMENT_TYPES.example) {
      unwrapNodes(editor, element.type);
    }

    Transforms.setNodes(editor, { type: ELEMENT_TYPES.listItem });
  }

  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.unorderedList,
    children: [],
  });
  selectEndPath(editor, path);
};

/**
 * Cambia il blocco in una lista ordinata
 * @param {Object} editor editor di Slate.js
 * @param {Object} element element di Slate.js
 */
export const changeToOrderedList = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);
  selectBlock(editor, path);

  if (LIST_BLOCKS.includes(element.type)) {
    unwrapNodes(editor, element.type);
  } else {
    if (element.type === ELEMENT_TYPES.example) {
      unwrapNodes(editor, element.type);
    }

    Transforms.setNodes(editor, { type: ELEMENT_TYPES.listItem });
  }

  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.orderedList,
    children: [],
  });
  selectEndPath(editor, path);
};

/**
 * Cambia il blocco in una lista alfabetica ordinata
 * @param {Object} editor editor di Slate.js
 * @param {Object} element element di Slate.js
 */
export const changeToAlphabeticList = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);
  selectBlock(editor, path);

  if (LIST_BLOCKS.includes(element.type)) {
    unwrapNodes(editor, element.type);
  } else {
    if (element.type === ELEMENT_TYPES.example) {
      unwrapNodes(editor, element.type);
    }

    Transforms.setNodes(editor, { type: ELEMENT_TYPES.listItem });
  }

  Transforms.wrapNodes(editor, {
    type: ELEMENT_TYPES.alphabeticList,
    children: [],
  });
  selectEndPath(editor, path);
};

/**
 * Wrappa il blocco in un highlight box
 * @param {Object} editor editor di Slate.js
 * @param {Array} path path di Slate.js
 */
export const wrapHighlightBox = (editor, path) => {
  Transforms.wrapNodes(
    editor,
    {
      type: ELEMENT_TYPES.highlightBox,
      background: BACKGROUND_COLORS.primary,
      children: [],
    },
    { at: path },
  );
};

/**
 * Transforma in quote
 * @param {Object} editor editor di Slate.js
 * @param {Array} path path di Slate.js
 */
export const changeToQuote = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);

  Transforms.setNodes(editor, { type: ELEMENT_TYPES.quoteParagraph });
  Transforms.wrapNodes(editor, { type: ELEMENT_TYPES.quoteText });
  Transforms.wrapNodes(editor, { type: ELEMENT_TYPES.quote }, { at: path });
  Transforms.insertNodes(
    editor,
    {
      type: ELEMENT_TYPES.quoteSource,
      children: [{ text: "(fonte)" }],
    },
    { at: path.concat(1) },
  );

  selectEndPath(editor, path);
};

/**
 * Transforma in Esempio
 * @param {Object} editor editor di Slate.js
 * @param {Array} path path di Slate.js
 */
export const changeToExample = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);

  selectBlock(editor, path);

  if (LIST_BLOCKS.includes(element.type)) {
    unwrapNodes(editor, element.type);
  }

  Transforms.setNodes(editor, { type: ELEMENT_TYPES.exampleParagraph });
  Transforms.wrapNodes(editor, { type: ELEMENT_TYPES.example });

  selectEndPath(editor, path);
};
