import React from "react";
import { ELEMENT_TYPES, MAP_AVAILABLE_BLOCKS } from "./constants";
import BlockCreationTools from "./BlockCreationTools";
import BlockTools from "./BlockTools";

const topLevelAvailableBlocks = MAP_AVAILABLE_BLOCKS[ELEMENT_TYPES.paragraph];

function TopLevelCreationTools() {
  return (
    <div className="block block-beginning">
      <div className="select-none cursor-default">
        <p className="py-4 w-full text-center text-base-content opacity-70 border-2 border-dotted rounded-md border-base-content">
          Aggiungi contenuti all&apos;inizio del capitolo
        </p>
      </div>

      <BlockTools type="creation-top">
        <BlockCreationTools
          path={[-1]}
          availableBlocks={topLevelAvailableBlocks}
        />
      </BlockTools>
    </div>
  );
}

export default TopLevelCreationTools;
