import paragraphType from "./Blocks/Paragraph/paragraphTypes";
import typeGlossario from "./InlineBlocks/Glossario/glossarioTypes";
import typeLink from "./InlineBlocks/BlockLink/blockLinkTypes";
import headingType from "./Blocks/Heading/headingTypes";
import highlightBoxType from "./Blocks/HighlightBox/highlightBoxTypes";
import listType from "./Blocks/List/listTypes";
import imageTypes from "./Blocks/Image/imageTypes";
import quoteType from "./Blocks/Quote/quoteTypes";
import mylimcontentType from "./Blocks/MyLIMContent/mylimcontentType";
import exampleType from "./Blocks/Example/exampleTypes";

/**
 * I vari tipi di blocco
 */
export const ELEMENT_TYPES = {
  paragraph: paragraphType,
  heading: headingType,
  highlightBox: highlightBoxType,
  image: imageTypes,
  unorderedList: listType.unordered,
  orderedList: listType.ordered,
  alphabeticList: listType.alphabeticList,
  listItem: listType.item,
  glossario: typeGlossario,
  quote: quoteType.quote,
  quoteText: quoteType.quoteText,
  quoteSource: quoteType.quoteSource,
  quoteParagraph: quoteType.quoteParagraph,
  link: typeLink,
  mylimcontent: mylimcontentType,
  example: exampleType.example,
  exampleParagraph: exampleType.exampleParagraph,
};

const ALL_BLOCKS = [
  ELEMENT_TYPES.paragraph,
  ELEMENT_TYPES.heading,
  ELEMENT_TYPES.highlightBox,
  ELEMENT_TYPES.image,
  ELEMENT_TYPES.glossario,
  ELEMENT_TYPES.unorderedList,
  ELEMENT_TYPES.orderedList,
  ELEMENT_TYPES.alphabeticList,
  ELEMENT_TYPES.listItem,
  ELEMENT_TYPES.quote,
  ELEMENT_TYPES.quoteText,
  ELEMENT_TYPES.quoteSource,
  ELEMENT_TYPES.link,
  ELEMENT_TYPES.example,
  ELEMENT_TYPES.mylimcontent,
];

/**
 * I blocchi che possono essere inseriti sotto al tipo blocco
 */
export const MAP_AVAILABLE_BLOCKS = {
  [ELEMENT_TYPES.heading]: ALL_BLOCKS,
  [ELEMENT_TYPES.paragraph]: ALL_BLOCKS,
  [ELEMENT_TYPES.highlightBox]: ALL_BLOCKS,
  [ELEMENT_TYPES.unorderedList]: ALL_BLOCKS,
  [ELEMENT_TYPES.orderedList]: ALL_BLOCKS,
  [ELEMENT_TYPES.alphabeticList]: ALL_BLOCKS,
  [ELEMENT_TYPES.image]: ALL_BLOCKS,
  [ELEMENT_TYPES.quote]: ALL_BLOCKS,
  [ELEMENT_TYPES.quoteParagraph]: [ELEMENT_TYPES.quoteParagraph],
  [ELEMENT_TYPES.example]: ALL_BLOCKS,
  [ELEMENT_TYPES.exampleParagraph]: [ELEMENT_TYPES.exampleParagraph],
  [ELEMENT_TYPES.mylimcontent]: ALL_BLOCKS,
  [ELEMENT_TYPES.listItem]: [],
};

/**
 *  Blocchi che usano il comportamento enter default di Slate.js
 */
export const BLOCKS_DEFAULT_ENTER = [ELEMENT_TYPES.listItem];

/**
 *  Blocchi che hanno sottoblocchi (non-inline) al loro interno
 */
export const BLOCK_WITH_CHILDREN = [
  ELEMENT_TYPES.highlightBox,
  ELEMENT_TYPES.orderedList,
  ELEMENT_TYPES.unorderedList,
  ELEMENT_TYPES.alphabeticList,
  ELEMENT_TYPES.quote,
  ELEMENT_TYPES.example,
];

/**
 * Blocchi con non hanno il tool di posizionamento
 */
export const BLOCK_WITHOUT_POSITION_TOOLS = [
  ELEMENT_TYPES.quoteText,
  ELEMENT_TYPES.quoteSource,
];

/**
 * Blocchi che sono di tipo lista
 */
export const LIST_BLOCKS = [
  ELEMENT_TYPES.unorderedList,
  ELEMENT_TYPES.orderedList,
  ELEMENT_TYPES.alphabeticList,
];
