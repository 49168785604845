import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Label from "@radix-ui/react-label";
import * as SwitchPrimitive from "@radix-ui/react-switch";

const SWITCH_TYPE_MAP = {
  primary: "toggle-primary",
  secondary: "toggle-secondary",
  accent: "toggle-accent",
};

const SWITCH_SIZE_MAP = {
  lg: "toggle-lg",
  md: "toggle-md",
  sm: "toggle-sm",
  xs: "toggle-xs",
};

const Switch = forwardRef((props, ref) => {
  const { label, children, size = "md", type = null, ...switchProps } = props;
  const thumbCls = classNames("toggle", {
    [SWITCH_SIZE_MAP[size]]: !!size,
    [SWITCH_TYPE_MAP[type]]: !!type,
  });

  return (
    <Label.Root className="flex items-center select-none">
      {label || children}
      <SwitchPrimitive.Root className="form-control ml-2" {...switchProps}>
        <SwitchPrimitive.Thumb
          className={thumbCls}
          ref={ref}
          data-testid="thumb"
        />
      </SwitchPrimitive.Root>
    </Label.Root>
  );
});

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onCheckedChange: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(SWITCH_TYPE_MAP)),
  size: PropTypes.oneOf(Object.keys(SWITCH_SIZE_MAP)),
  children: PropTypes.node,
};

Switch.displayName = "Switch";

export default Switch;
