import React from "react";
import { Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import { MdImage } from "react-icons/md";
import PropTypes from "prop-types";

import defaultImage from "~/src/assets/blocco-immagine.png";
import { ELEMENT_TYPES } from "../../constants";
import { addBlockAndFocus } from "../../utils";
import { ButtonAction } from "../../../components/Button";

function ToolbarBtnImage({ path }) {
  const editor = useSlateStatic();

  const onToggle = () => {
    addBlockAndFocus({
      editor,
      path,
      cursorToEnd: true,
      insertNodes: (newPath) => {
        Transforms.insertNodes(
          editor,
          {
            type: ELEMENT_TYPES.image,
            url: defaultImage,
            aspectRatio: "1200/700",
            altText: "",
            children: [{ text: "Didascalia" }],
          },
          { at: newPath },
        );
      },
    });
  };

  return (
    <ButtonAction
      ariaLabel="Aggiungi immagine"
      onClick={onToggle}
      Icon={MdImage}
    />
  );
}

ToolbarBtnImage.propTypes = {
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo blocck
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ToolbarBtnImage;
