import { Editor, Element, Transforms, Node, Path } from "slate";
import { ReactEditor } from "slate-react";

import { BLOCKS_DEFAULT_ENTER, ELEMENT_TYPES } from "./constants";

export const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
  });

  return !!match;
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

/**
 * Muove alla fine del blocco dove si trova la selection
 * @param {*} editor
 */
export const moveToEndBlockSelection = (editor) => {
  if (editor.selection?.anchor?.path) {
    const {
      anchor: { path: pathAnchor },
    } = editor.selection;
    Transforms.select(editor, Editor.end(editor, pathAnchor));
  }
};

/**
 * Ritorna se il blocco attuale deve utilizzare il comportamente enter di default di Slate.js
 * @param {*} editor
 */
export const isBlockDefaultEnter = (editor) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      BLOCKS_DEFAULT_ENTER.includes(n.type),
  });

  return !!match;
};

/**
 * Funzione che aggiunge un blocco e lo mette in focus.
 * @param {*} editor Instanza Editor
 * @param {number[]} path Path dopo il quale si vuole inserire il nuovo blocck
 * @param {Function} insertNodes Callback che chiama il metodo quando è il momento di inserire i nodi. Viene passato
 * come parametro il nuovo percorso. Verra quindi chiamata ad esempio con insertNodes([1, 4, 5]) e il corpo della funzione
 * dovrà occuparsi di inserire i nodi che le interessano in quel path.
 * @param {number} [indexToFocus] Se necessario andare in focus non sul nuovo blocco o sul primo di figlio. Se è un blocco semplice
 * o con solo un figlio non occorre passare niente, mentre se il blocco ha ad esempio due figli e si vuole andare in focus sul secondo
 * basta passare il valore "1"
 * @param {boolean} cursorToEnd Se true posiziona il cursore alla fine del blocco.
 */
export const addBlockAndFocus = ({
  editor,
  path,
  insertNodes,
  indexToFocus,
  cursorToEnd = false,
}) => {
  const newPath = [...path];
  newPath[newPath.length - 1] = newPath[newPath.length - 1] + 1;

  if (!ReactEditor.isFocused()) {
    ReactEditor.focus(editor);
  }

  insertNodes(newPath);

  const pathFocus = indexToFocus ? [...newPath, indexToFocus] : newPath;

  if (cursorToEnd) {
    Transforms.select(editor, Editor.end(editor, pathFocus));
    return;
  }

  Transforms.select(editor, {
    anchor: Editor.start(editor, pathFocus),
    focus: Editor.start(editor, pathFocus),
  });
};

export const stringifyNode = (node, { separator = " " } = {}) => {
  const string = node.children.map((n) => Node.string(n)).join(separator);

  if (separator !== " ") {
    return string
      .normalize("NFKD")
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .trim()
      .replace(/[-\s]+/g, separator);
  }

  return string;
};

/**
 * Funzione ricorsiva che aggiungere la nuova sezione del sommario al posto giusto, mantenendo ordinati i titoli per peso.
 * @param {Object} prevSection - Sezione precedente;
 * @param {number} prevSection.weight - Peso della sezione precedente.
 * @param {Object[]} prevSection.children - Sottosezioni della sezione precedente, stessa struttura della sezione corrente.
 * @param {string} prevSection.titolo - Titolo della sezione precedente.
 * @param {number} prevSection.id - Id della sezione precedente.
 * @param {Object} newSection - Nuova sezione;
 * @param {number} newSection.weight - Peso della nuova sezione.
 * @param {Object[]} newSection.children - Sottosezioni della nuova sezione, vuoto.
 * @param {string} newSection.titolo - Titolo della nuova sezione.
 * @param {number} newSection.id - Id della nuova sezione.
 * @returns {Object} result - risultato della ricorsione.
 * @returns {boolean} result.added - Sottosezione aggiunta o meno.
 * @returns {boolean} result.prevSection - Sottosezione precedente aggiornata.
 */
const buildSummaryNode = (prevSection, newSection) => {
  const updatedPrevSection = prevSection;

  if (
    updatedPrevSection.children.length === 0 &&
    updatedPrevSection.weight < newSection.weight
  ) {
    updatedPrevSection.children.push(newSection);
    updatedPrevSection.flatChildrenSlugs.push(newSection.slug);
    return { prevSection: updatedPrevSection, added: true };
  }

  if (updatedPrevSection.weight < newSection.weight) {
    // Aggiunge sempre all'ultimo children
    const result = buildSummaryNode(
      updatedPrevSection.children[updatedPrevSection.children.length - 1],
      newSection,
    );
    if (result.added) {
      updatedPrevSection.children[updatedPrevSection.children.length - 1] =
        result.prevSection;
      updatedPrevSection.flatChildrenSlugs = [
        ...new Set(
          updatedPrevSection.flatChildrenSlugs.concat(
            result.prevSection.flatChildrenSlugs,
          ),
        ),
      ]; // remove duplicates
    } else {
      updatedPrevSection.children.push(newSection);
      updatedPrevSection.flatChildrenSlugs.push(newSection.slug);
    }

    return { prevSection: updatedPrevSection, added: true };
  }

  return { prevSection: updatedPrevSection, added: false };
};

/**
 * Funzione che riceve ogni blocco heading e genera la struttura del sommario.
 * @param {Object[]} availableHeadings - Lista di blocchi;
 * @param {string} availableHeadings[].id - Id del blocco;
 * @param {string} availableHeadings[].slug - Ancora interna al blocco;
 * @param {string} availableHeadings[].type - Tipo del blocco;
 * @param {number} availableHeadings[].weight - Peso del blocco heading;
 * @param {Object[]} availableHeadings[].children - Testi del blocco;
 * @param {string} availableHeadings[].children[].text - Testo del blocco;
 * @returns {Object[]} result - Sommario generato.
 * @returns {number} result.id - Id sezione.
 * @returns {string} result.titolo - Titolo della sezione.
 * @returns {string} result.weight - Peso della sezione.
 * @returns {Object[]} result.children - Sottosezioni.
 */
export const buildSummary = (availableHeadings = []) => {
  const summary = [];

  for (let index = 0; index < availableHeadings.length; index += 1) {
    const currentBlock = availableHeadings[index];
    const currentSection = {
      id: currentBlock.id,
      title: stringifyNode(currentBlock),
      mylim_id: currentBlock.mylim_id || null,
      slug: currentBlock.slug,
      weight: currentBlock.weight,
      flatChildrenSlugs: [],
      children: [],
    };

    let lastSection = summary[summary.length - 1];

    if (summary.length > 0 && lastSection.weight < currentSection.weight) {
      lastSection = buildSummaryNode(lastSection, currentSection).prevSection;
    } else {
      summary.push(currentSection);
    }
  }

  return summary;
};

/**
 * Ritorna se due array hanno lo stesso contenuto
 * @param {Array} a
 * @param {Array} b
 */
export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  for (let i = a.length - 1; i >= 0; i -= 1) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};

/**
 * Ritorna un titolo in formato Slate.js
 * @param {string} title
 * @param {number} mylim_id
 * @param {number} level
 */
const createHeader = (title, mylimId, level) => ({
  type: ELEMENT_TYPES.heading,
  mylim_id: mylimId,
  children: [{ text: title }],
  weight: level + 1,
});

/**
 * Funzione che genera un array di titoli in formato Slate.js a partire dal toc.
 * @param {Array} toc Array di titoli dalle api
 * @param {Array} headers Array che la funziona sta popolando
 * @param {number} level livello di ricorsione
 */
export const createEditorContentsFromToc = (toc, headers = [], level = 0) => {
  toc.forEach((section) => {
    headers.push(createHeader(section.title, section.mylim_id, level));

    if (section.children?.length) {
      createEditorContentsFromToc(section.children, headers, level + 1);
    }
  });

  if (headers.length === 0) {
    return [{ type: ELEMENT_TYPES.paragraph, children: [{ text: "" }] }];
  }

  return headers;
};

/**
 * Funzione che parsa il toc e rimuove i parametri extra prima di inviarli a backend
 * @param {Object} toc
 */
export const parseTocToSave = (toc) => {
  const { weight, flatChildrenSlugs, ...properties } = toc;

  if (properties.children?.length > 0) {
    properties.children = properties.children.map((t) => parseTocToSave(t));
  }

  return properties;
};

/**
 * Funzione che cerca un nodo di tipo `type` risalendo la catena dei nodi
 * `previous` a partire da un `path`. Se un tale nodo non esiste, ritorna null.
 * Se il nodo indicato da `path` soddisfa il requisito sul tipo => ritorna quel
 * nodo.
 * @param {Path} path - la path del nodo da cui far partire la ricerca.
 * @param {Node} root - il nodo root al di sotto del quale avviene la ricerca.
 * @param {String} type - il tipo del nodo cercato.
 * @returns {Node|null} il primo nodo di tipo `type` incontrato risalendo l'albero o `null`.
 */
export const findPreceedingNodeByType = (path, root, type) => {
  if (path?.[0] === -1) {
    return null;
  }
  let p = [...path];
  do {
    const node = Node.get(root, p);
    if (node?.type === type) {
      return node;
    }
    // eslint-disable-next-line no-cond-assign
  } while (Path.hasPrevious(p) && (p = Path.previous(p)));
  return null;
};
