import { ELEMENT_TYPES } from "../constants";

export const ELEMENT_TAGS = {
  A: (el) => ({ type: ELEMENT_TYPES.link, url: el.getAttribute("href") }),
  H1: () => ({ type: ELEMENT_TYPES.heading, weight: 1 }),
  H2: () => ({ type: ELEMENT_TYPES.heading, weight: 2 }),
  H3: () => ({ type: ELEMENT_TYPES.heading, weight: 3 }),
  H4: () => ({ type: ELEMENT_TYPES.heading, weight: 4 }),
  H5: () => ({ type: ELEMENT_TYPES.heading, weight: 4 }),
  H6: () => ({ type: ELEMENT_TYPES.heading, weight: 4 }),
  LI: () => ({ type: ELEMENT_TYPES.listItem }),
  P: () => ({ type: ELEMENT_TYPES.paragraph }),
  UL: () => ({ type: ELEMENT_TYPES.unorderedList }),
};

export const TEXT_TAGS = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  B: () => ({ bold: true }),
  STRONG: () => ({ bold: true }),
};
