import React from "react";
import { MdPerson, MdLogout } from "react-icons/md";
import PropTypes from "prop-types";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "~/src/components/Popover";
import Button from "~/src/components/Button";
import Icon from "~/src/components/Icon";
import Dialog from "~/src/components/Dialog";
import { useAuth } from "../../Auth";

import Logo from "svgr:~/src/assets/ll-logo.svg";
import LogoTitle from "svgr:~/src/assets/ll-title.svg";

function Header({ bookTitle }) {
  const {
    userInfo: { role },
    logout,
  } = useAuth();

  return (
    <header className="navbar topbar bg-neutral text-neutral-content align-baseline">
      <div className="navbar-start pl-2 text-neutral-content">
        <Logo
          className="h-12 aspect-square p-1"
          role="img"
          focusable="false"
          title="Libro liquido"
          titleId="ll-logo"
        />
        <LogoTitle
          className="h-8 pl-2 pt-2 hidden lg:block"
          aria-hidden="true"
          focusable="false"
        />
      </div>
      <div className="navbar-center ">
        <h1 className="text-lg pt-2">{bookTitle}</h1>
      </div>
      <div className="navbar-end">
        <Popover>
          <PopoverTrigger asChild>
            <Button type="ghost" square>
              <Icon
                icon={MdPerson}
                label="Gestisci account"
                className="w-full h-full p-2"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <ul className="flex flex-col space-y-4">
              <li>
                <span>Ruolo: {role}</span>
              </li>
              <li>
                <Dialog
                  title="Informazioni su Libro liquido"
                  trigger={
                    <Button type="accent" classes="btn-block">
                      Informazioni
                    </Button>
                  }
                >
                  {process.env.VERSION && (
                    <p className="text-center mb-2">
                      Versione {process.env.VERSION}
                    </p>
                  )}
                  <h4 className="underline font-bold mb-2">Credits</h4>
                  <p className="w-full">
                    Font ad alta leggibilità biancoenero® di Biancoenero
                    Edizioni
                    <br />
                    Disegnata da Riccardo Lorusso e Umberto Mischi
                    <br />
                    La font è gratuita per uso non commerciale; info su{" "}
                    <a
                      href="http://biancoeneroedizioni.com"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      className="link link-hover link-secondary"
                    >
                      biancoeneroedizioni.com
                    </a>
                  </p>
                </Dialog>
              </li>
              <li>
                <Button type="primary" classes="btn-block" onClick={logout}>
                  <Icon icon={MdLogout} className="mr-2" />
                  Logout
                </Button>
              </li>
            </ul>
          </PopoverContent>
        </Popover>
        <div className="dropdown dropdown-end"></div>
      </div>
    </header>
  );
}

Header.propTypes = {
  bookTitle: PropTypes.string,
};

export default Header;
