import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { MdAdd, MdMoreVert, MdTextFields } from "react-icons/md";

import Icon from "~/src/components/Icon";

export const BLOCK_TOOLS_CONFIG = {
  position: {
    icon: MdMoreVert,
    label: "strumenti posizionamento",
    classModifiers: "block-tools--left",
  },
  creation: {
    icon: MdAdd,
    label: "strumenti creazione",
    classModifiers: "block-tools--bottom",
  },
  "creation-top": {
    icon: MdAdd,
    label: "strumenti creazione all'inizio",
    classModifiers: "block-tools--bottom",
  },
  modifiers: {
    icon: MdTextFields,
    label: "strumenti modifica",
    classModifiers: "block-tools--right",
  },
};

/**
 * Componente che renderizza un bottone che apre un popover per il controllo del blocco.
 * L'onMouseDown serve perchè una volta cliccato un bottone dentro il contenuto del popover, il focus-within
 * lascia il popover aperto quando si esce dal mouseOver.
 * @param {*} param
 */
function BlockTools({ children, type, hasNestedBlocks = false }) {
  const infos = BLOCK_TOOLS_CONFIG[type];
  const rootClass = classNames("block-tools", infos.classModifiers, {
    "block-tools--parent": hasNestedBlocks,
  });

  return (
    <div
      className={rootClass}
      contentEditable={false}
      data-testid="block-tools"
    >
      <button className="block-tools__trigger" aria-label={infos.label}>
        <Icon icon={infos.icon} size="20px" />
      </button>
      {/* TODO: definire ruole */}
      {/* eslint-disable-next-line */}
      <div
        className="block-tools__contents"
        onMouseDown={(e) => e.preventDefault()}
        data-testid="block-tools-contents"
      >
        {children}
      </div>
    </div>
  );
}

BlockTools.propTypes = {
  /**
   * Contenuto del popover
   */
  children: PropTypes.node,
  /**
   * Tipo del bottone, usato per determinare la posizione
   */
  type: PropTypes.oneOf(Object.keys(BLOCK_TOOLS_CONFIG)).isRequired,
  /**
   * Se il blocco è un blocco complesso, come ad esempio HighlightBox
   */
  hasNestedBlocks: PropTypes.bool,
};

export default BlockTools;
