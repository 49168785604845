import React from "react";
import PropTypes from "prop-types";

import { MdMusicNote, MdSmartDisplay } from "react-icons/md";
import Icon from "~/src/components/Icon";
import { ModalPlayer } from "~/src/components/Player";

import logoMyLIM from "~/src/assets/myLIM-icon.png";

const MYLIM_RES_TYPE_ICONS = {
  audio: MdMusicNote,
  video: MdSmartDisplay,
};

function getIconForType(type) {
  const icon = MYLIM_RES_TYPE_ICONS[type];
  return icon ? <Icon icon={icon} /> : <></>;
}

function renderResource(row) {
  const { id, type, titolo, url, sottotitoli = [] } = row;
  const mediaData = { titolo, url, sottotitoli };
  return (
    <li
      key={id}
      className="text-base bg-base-100 odd:bg-base-200 hover:bg-primary hover:text-accent-content"
    >
      <ModalPlayer media={mediaData}>
        <button className="w-full flex items-center px-6 py-2">
          <span className="mr-6">{getIconForType(type)}</span>
          <span className="flex-1 text-left whitespace-nowrap overflow-x-hidden overflow-ellipsis">
            {titolo}
          </span>
        </button>
      </ModalPlayer>
    </li>
  );
}

function MyLIMContent({ attributes, element, children }) {
  const { contents } = element;
  return (
    <aside
      {...attributes}
      contentEditable={false}
      // NOTE: the `not-prose` class disables TailwindCSS/typography in the block
      className="card compact border border-neutral rounded not-prose my-4"
    >
      <div className="card-title flex items-center justify-between px-4 py-4 border-b border-neutral select-none">
        <h1 className="uppercase text-lg tracking-wider font-bold cursor-default">
          Contenuti da myLIM
        </h1>
        <img src={logoMyLIM} alt="myLIM Logo" className="h-6" />
      </div>
      <ul className="max-h-64 lg:max-h-52 overflow-y-scroll">
        {contents.map((row) => renderResource(row))}
      </ul>
      <>{children}</>
    </aside>
  );
}

MyLIMContent.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any,
  children: PropTypes.any,
};

export default MyLIMContent;
