import React from "react";
import PropTypes from "prop-types";
import { useSlateStatic } from "slate-react";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";
import {
  ButtonConverterToTitle,
  ButtonConverterToBulletedList,
  ButtonConverterToOrderedList,
  ButtonWrapHighlightBox,
  ButtonConverterToAlphabeticList,
  ButtonConverterToParagraph,
} from "~/src/Editor/BlockConverter";

import useModifiers from "../../useModifiersContext";

function Example({ attributes, children, element }) {
  const editor = useSlateStatic();

  const converterBtns = [
    <ButtonConverterToTitle
      key="changeToTitle"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToParagraph
      key="changeToParagraph"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToBulletedList
      key="changeToList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToOrderedList
      key="changeToOrderedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToAlphabeticList
      key="changeToAlphabeticalList"
      editor={editor}
      element={element}
    />,
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ converterBtns, element });

  return (
    <div className="example-block" {...attributes}>
      {children}
    </div>
  );
}

Example.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

function ExampleParagraph({ attributes, children }) {
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  return <p {...attributes}>{children}</p>;
}

ExampleParagraph.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

export { Example, ExampleParagraph };
