import Axios from "axios";

/**
 * Funzioni per interagire con l'API di autenticazione
 */

const MylimLoginService = Axios.create({
  timeout: 1000,
});

/**
 * Endpoint autenticazione Loescher
 * @param {*} username - l'username
 * @param {*} hashedPswd - la password hashata in md5
 * @returns {Promise<string>} il token JWT o null o eccezione Axios
 */
export async function login(username, hashedPswd) {
  const res = await MylimLoginService.post(
    "https://www.cloudschooling.it/loescher/api/v1/api-token-auth/",
    {
      username,
      password: hashedPswd,
    },
  );
  return res?.data?.token;
}
