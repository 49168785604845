import React, { useEffect, useState } from "react";

import Button from "../Button";

function CookieBanner() {
  const [requestCookies, setRequestCookies] = useState(false);

  useEffect(() => {
    const gdprPreferencesCookie = localStorage.getItem("gdpr_preferences_set");

    if (!gdprPreferencesCookie) {
      return setRequestCookies(true);
    }

    const parsed = parseInt(gdprPreferencesCookie, 10);

    if (!parsed) {
      localStorage.removeItem("gdpr_preferences_set");
      return setRequestCookies(true);
    }

    // Se il cookie è stato impostato più di 6 mesi fa
    if (Date.now() - parsed > 15552000000) {
      localStorage.removeItem("gdpr_preferences_set");
      return setRequestCookies(true);
    }
  }, []);

  const onClick = () => {
    localStorage.setItem("gdpr_preferences_set", Date.now());
    setRequestCookies(false);
  };

  if (!requestCookies) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p className="cookie-banner__text">
        Sono presenti esclusivamente cookie di tipo tecnico assolutamente
        essenziali per il corretto funzionamento del sito web e per migliorare
        l’esperienza di navigazione.{" "}
        <a
          className="whitespace-nowrap underline"
          href="https://loescher.it/privacy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Informativa Completa
        </a>
      </p>
      <div className="cookie-banner__actions">
        <Button type="primary" onClick={onClick}>
          Accetta
        </Button>
      </div>
    </div>
  );
}

export default CookieBanner;
