import React from "react";
import { Transforms, createEditor } from "slate";
import {
  Slate,
  Editable,
  withReact,
  useReadOnly,
  ReactEditor,
  useSlateStatic,
} from "slate-react";
import * as Popover from "@radix-ui/react-popover";
import PropTypes from "prop-types";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";

/**
 * Componente che renderizza un fumetto sopra a una o più parole per indicare una nota associata ad essi.
 * Utilizza un'istanza interna di Slate per gestire il testo della nota.
 * @param {*} param
 */
function Glossario({ attributes, element, children }) {
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);
  const [contents, setContents] = React.useState([
    { children: element.description },
  ]);
  const parentEditor = useSlateStatic();
  const isReadOnly = useReadOnly();

  const editor = React.useMemo(() => withReact(createEditor()), []);

  React.useEffect(() => {
    const path = ReactEditor.findPath(parentEditor, element);
    Transforms.setNodes(
      parentEditor,
      { description: contents[0].children },
      { at: path },
    );
  }, [contents, parentEditor]);

  return (
    <Popover.Root>
      <Popover.Trigger>
        <span
          {...attributes}
          className="hover:cursor-pointer border-b-2 border-primary"
        >
          {children}
        </span>
      </Popover.Trigger>
      <Popover.Content
        side="top"
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="bg-neutral-focus text-neutral-content rounded p-2 z-editor-dialog max-w-[250px] sm:max-w-lg"
      >
        <Popover.Arrow />
        <Slate editor={editor} value={contents} onChange={setContents}>
          <Editable readOnly={isReadOnly} />
        </Slate>
      </Popover.Content>
    </Popover.Root>
  );
}

Glossario.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
  element: PropTypes.shape({
    /**
     * Testo contenuto nel fumetto della nota
     */
    description: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Glossario;
