import React from "react";
import { useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { MdTexture } from "react-icons/md";
import PropTypes from "prop-types";

import { ELEMENT_TYPES } from "../../constants";
import { BACKGROUND_COLORS } from "./HighlightBoxModifiers";
import { addBlockAndFocus } from "../../utils";
import { ButtonAction } from "../../../components/Button";

function ToolbarBtnHighlightBox({ path }) {
  const editor = useSlateStatic();

  const onToggle = () => {
    addBlockAndFocus({
      editor,
      path,
      insertNodes: (newPath) => {
        Transforms.insertNodes(
          editor,
          { type: ELEMENT_TYPES.heading, weight: 2, children: [{ text: "" }] },
          { at: newPath },
        );
        Transforms.wrapNodes(
          editor,
          {
            type: ELEMENT_TYPES.highlightBox,
            background: BACKGROUND_COLORS.primary,
            children: [],
          },
          { at: newPath },
        );
      },
    });
  };

  return (
    <ButtonAction
      ariaLabel="Aggiungi blocco in evidenza"
      onClick={onToggle}
      Icon={MdTexture}
    />
  );
}

ToolbarBtnHighlightBox.propTypes = {
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo blocck
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ToolbarBtnHighlightBox;
