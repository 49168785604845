import { Transforms } from "slate";
import { ELEMENT_TYPES } from "../constants";
import { makeDeserializer } from "./makeDeserializer";

export const withDocxDeserializer = (editor) => {
  const { insertData } = editor;
  const deserialize = makeDeserializer();

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const html = data.getData("text/html");

    // Incolla da Slate
    if (html && html.includes("data-slate-fragment")) {
      insertData(data);
      return;
    }

    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);

      if (fragment[0].type !== ELEMENT_TYPES.paragraph) {
        Transforms.insertNodes(editor, fragment);
      } else {
        // Se è un paragrafo incollo i children [{ text: 'prova' }] invece che l'intero paragrafo per
        // incollare il contenuto dove si trova il cursore e non in un nuovo paragrafo sotto
        Transforms.insertNodes(editor, fragment[0].children);
        Transforms.insertNodes(editor, fragment.slice(1));
      }

      return;
    }

    insertData(data);
  };

  return editor;
};
