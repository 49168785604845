import React from "react";
import { Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import { MdFormatQuote, MdLocalParking } from "react-icons/md";
import PropTypes from "prop-types";

import { ELEMENT_TYPES } from "../../constants";
import { addBlockAndFocus } from "../../utils";
import { ButtonAction } from "../../../components/Button";

function ToolbarBtnQuote({ path }) {
  const editor = useSlateStatic();

  const onToggle = () => {
    addBlockAndFocus({
      editor,
      path,
      insertNodes: (newPath) => {
        Transforms.insertNodes(
          editor,
          {
            type: ELEMENT_TYPES.quote,
            children: [
              {
                type: ELEMENT_TYPES.quoteText,
                children: [
                  {
                    type: ELEMENT_TYPES.quoteParagraph,
                    children: [{ text: "" }],
                  },
                ],
              },
              {
                type: ELEMENT_TYPES.quoteSource,
                children: [{ text: "(fonte)" }],
              },
            ],
          },
          { at: newPath },
        );
      },
    });
  };

  return (
    <ButtonAction
      ariaLabel="Aggiungi citazione"
      onClick={onToggle}
      Icon={MdFormatQuote}
    />
  );
}

ToolbarBtnQuote.propTypes = {
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo blocck
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

function ToolbarBtnQuoteParagraph({ path }) {
  const editor = useSlateStatic();

  const onToggle = () => {
    addBlockAndFocus({
      editor,
      path,
      insertNodes: (newPath) => {
        Transforms.insertNodes(
          editor,
          { type: ELEMENT_TYPES.quoteParagraph, children: [{ text: "" }] },
          {
            at: newPath,
          },
        );
      },
    });
  };

  return (
    <ButtonAction
      ariaLabel="Aggiungi paragrafo citazione"
      onClick={onToggle}
      Icon={MdLocalParking}
    />
  );
}

ToolbarBtnQuoteParagraph.propTypes = {
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo block
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ToolbarBtnQuote;
export { ToolbarBtnQuoteParagraph };
