import React, { Suspense, useState } from "react";
import PropTypes from "prop-types";
import * as Dialog from "@radix-ui/react-dialog";

import { MdClose } from "react-icons/md";
import Icon from "~/src/components/Icon";

// Carichiamo il player video solo al bisogno
const Player = React.lazy(() => import("./Player"));

export const ModalPlayer = ({ children, media }) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Trigger asChild onClick={() => setOpen(true)}>
        {children}
      </Dialog.Trigger>
      <Dialog.Overlay className="fixed w-screen h-screen bg-gray-900 opacity-70" />
      <Dialog.Content
        className="fixed w-full max-w-screen-xl max-h-screen right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2"
        onEscapeKeyDown={closeModal}
        onPointerDownOutside={closeModal}
      >
        <div className="max-w-screen-xl max-h-screen m-4 p-0 rounded">
          <Suspense fallback={<div>Caricamento ...</div>}>
            <Player
              media={media}
              closeBtn={
                <button onClick={closeModal}>
                  <Icon
                    icon={MdClose}
                    label="Chiudi player multimediale"
                    size="1.2rem"
                  />
                </button>
              }
            />
          </Suspense>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

ModalPlayer.propTypes = {
  // I dati del file multimediale da riprodurre
  media: PropTypes.shape({
    titolo: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    sottotitoli: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  // L'elemento che triggera l'apparizione del ModalPlayer
  children: PropTypes.element,
};
