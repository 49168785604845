import UAParser from "ua-parser-js";

const parsedUA = new UAParser();
const infos = parsedUA.getResult();

const device = infos.device;
const osName = parsedUA.getOS();

/**
 * Determina se siamo su un dispositivo mobile (telefono o tablet).
 *
 * @returns {boolean} ritorna `true` se siamo su dispositivo mobile (tel/tablet)
 */
export const isMobile = () =>
  !!(
    ["mobile", "tablet"].includes(device.type) ||
    /**
     * workaround per fixare un baco di ua-parser-js sul riconoscimento
     * dei dispositivi mobile ios / mac OS
     * rif. https://github.com/faisalman/ua-parser-js/issues/387
     */
    osName?.name === "iOS" ||
    (window.orientation !== undefined && osName?.name === "Mac OS")
  );
