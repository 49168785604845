import React from "react";
import { useFocused, useSelected } from "slate-react";

/**
 * Il contesto React a cui i blocchi accederanno tramite l'hook `useToolbar`.
 *
 * NOTA: Questo contesto DEVE essere innestato dentro ad un Editor di SlateJS
 * poichè fa uso dell'hook `useSelected` di SlateJS.
 */
export const ToolbarContext = React.createContext({
  buttons: [],
  setButtons: null,
});

export const ToolbarContextProvider = ({ children, value }) => (
  <ToolbarContext.Provider value={value}>{children}</ToolbarContext.Provider>
);

/**
 * Hook per gestire la toolbar con gli strumenti di formattazione inline.
 * L'hook viene chiamato all'interno dei blocchi per definire se e quali bottoni
 * saranno disponibili per la formattazione inline del contenuto del blocco.
 *
 * Esempio:
 * ```
 *   useToolbar([<ToolbarBtn props={...} />, <ToolbarBtn props={...} />])
 * ```
 * oppure ignorando il parametro per non visualizzare la toolbar con gli
 * gli strumenti di formattazione.
 * ```
 *   useToolbar()
 * ```
 *
 *
 * @param {Array<ReactElement> | undefined} btns - array con i bottoni da visualizzare
 */
const useToolbar = (btns = []) => {
  const isSelected = useSelected();
  const isEditorFocused = useFocused();
  const { setButtons } = React.useContext(ToolbarContext);

  React.useEffect(() => {
    if (!isEditorFocused) setButtons([]);
    else if (isSelected) setButtons(btns);
  }, [isSelected, isEditorFocused]);
};

export default useToolbar;
