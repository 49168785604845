import { Text, Transforms } from "slate";

import { ELEMENT_TYPES } from "../../constants";

/**
 * Se l'utente cancella l'ultimo paragrafo all'interno del blockquote viene inserito un nuovo paragrafo vuoto
 *
 * @param {Object} editor editor di Slate.js
 * @param {Object} node Node di Slate.js
 * @param {Array[number]} path Path del node di Slate.js
 */
const normalizeQuote = (editor, node, path) => {
  if (node.children.length < 2) {
    return;
  }

  const quoteText = node.children[0];

  // Se è stato cancellato l'ultimo paragrafo il children e' una leaf
  if (Text.isText(quoteText.children[0])) {
    Transforms.insertNodes(
      editor,
      { type: ELEMENT_TYPES.quoteParagraph, children: [{ text: "" }] },
      { at: path.concat([0, 0]) },
    );
  }
};

export default normalizeQuote;
