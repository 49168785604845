import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Mappiamo la prop type in uno stile bottone di DaisyUI
const BUTTON_TYPE_MAP = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  accent: "btn-accent",
  info: "btn-info",
  success: "btn-success",
  warning: "btn-warning",
  error: "btn-error",
  ghost: "btn-ghost",
};

const BUTTON_SIZE_MAP = {
  lg: "btn-lg",
  md: "btn-md",
  sm: "btn-sm",
  xs: "btn-xs",
};

/**
 * Componente ButtonBase stilato con DaisyUI
 * vedi https://daisyui.com/components/button
 * @param {*} vedi propType
 */
const ButtonBase = forwardRef(
  (
    {
      text,
      onClick,
      children,
      classes = "",
      type = null,
      square = false,
      size = "md",
      outline = false,
      ariaLabel = "",
      ...props
    },
    ref,
  ) => {
    const btnCls = classNames(`btn ${classes}`, {
      [BUTTON_TYPE_MAP[type]]: !!type,
      [BUTTON_SIZE_MAP[size]]: !!size,
      "btn-square": square,
      "btn-outline": outline,
    });

    return (
      <button
        className={btnCls}
        onClick={onClick}
        ref={ref}
        aria-label={ariaLabel}
        {...props}
      >
        {text || children}
      </button>
    );
  },
);

ButtonBase.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(BUTTON_TYPE_MAP)),
  square: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(BUTTON_SIZE_MAP)),
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
};

ButtonBase.displayName = "ButtonBase";

export default ButtonBase;
