import { Editor, Transforms, Element as SlateElement } from "slate";

/**
 * Seleziona l'intero blocco per poter applicare trasformazioni
 * @param {Object} editor Editor di Slate.js
 * @param {Array} path path dell'elemento
 */
export const selectBlock = (editor, path) => {
  Transforms.select(editor, {
    anchor: Editor.start(editor, path),
    focus: Editor.end(editor, path),
  });
};

/**
 * Se si passa il parametro at applica l'unwrap
 * al quel path, altrimenti applica l'unwrap alla selection.
 * @param {Object} editor Editor di Slate.js
 * @param {Array} type il tipo di elemento da rimuovere
 * @param {Array} [undefined] path dove applicare l'unwrap
 */
export const unwrapNodes = (editor, type, at) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
    at,
  });
};

/**
 * Rimuove gli stili dalla selezione
 * @param {Object} editor Editor di Slate.js
 */
export const removeMarks = (editor) => {
  Editor.removeMark(editor, "bold");
  Editor.removeMark(editor, "italic");
  Editor.removeMark(editor, "code");
  Editor.removeMark(editor, "uppercase");
};

/**
 * Sposta il cursore alla fine del path indicato
 * @param {Object} editor Editor di Slate.js
 * @param {Array} path path dell'elemento
 */
export const selectEndPath = (editor, path) => {
  Transforms.select(editor, {
    anchor: Editor.end(editor, path),
    focus: Editor.end(editor, path),
  });
};
