import Axios from "axios";

export const Api = Axios.create({
  baseURL:
    process.env.BACKEND_URL ||
    "https://dev.cloudschooling.it/libroliquido/api/",
});

export async function getBookSummary(isbn) {
  const result = await Api.get(`v1/books/${isbn}/`);

  return result.data;
}

export async function getChapter(chapterId) {
  const result = await Api.get(`v1/chapters/${chapterId}/`);

  return result.data;
}

export async function saveChapter(chapterId, data) {
  const result = await Api.put(`v1/chapters/${chapterId}/`, data);

  return result.data;
}

export async function postChapter(bookId, order) {
  const result = await Api.post("v1/chapters/", {
    title: "Nuovo capitolo",
    book: bookId,
    order,
  });

  return result.data;
}

export async function apiDeleteChapter(chapterId) {
  await Api.delete(`v1/chapters/${chapterId}`);
}

export async function fetchMyLIMContentForSection(isbn, sectionID) {
  const url = `https://www.cloudschooling.it/mialim2/api/v1/book/audiolibri/?isbn=${isbn}&sezione=${sectionID}`;
  const result = await Api.get(url);

  return result.data;
}

export async function apiUploadImage(name, formData) {
  const result = await Api.post(`v1/image/${name}/`, formData);

  return result.data;
}
