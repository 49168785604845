import React from "react";
import { Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import PropTypes from "prop-types";
import {
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdChecklistRtl,
} from "react-icons/md";

import { addBlockAndFocus } from "../../utils";
import { ELEMENT_TYPES } from "../../constants";
import { ButtonAction } from "../../../components/Button";

const ariaLabelText = {
  [ELEMENT_TYPES.orderedList]: "Aggiungi lista ordinata",
  [ELEMENT_TYPES.alphabeticList]: "Aggiungi lista alfabetica",
  [ELEMENT_TYPES.unorderedList]: "Aggiungi lista non ordinata",
};

const iconButton = {
  [ELEMENT_TYPES.orderedList]: MdFormatListNumbered,
  [ELEMENT_TYPES.alphabeticList]: MdChecklistRtl,
  [ELEMENT_TYPES.unorderedList]: MdFormatListBulleted,
};

/**
 * Bottone che aggiunge un element lista (ordinata o non ordinata) all'editor.
 * @param {*} param
 */
function ToolbarBtnList({ format, path }) {
  const editor = useSlateStatic();

  const onToggle = () => {
    addBlockAndFocus({
      editor,
      path,
      insertNodes: (newPath) => {
        Transforms.insertNodes(
          editor,
          {
            type: ELEMENT_TYPES.listItem,
            children: [{ text: "" }],
          },
          { at: newPath },
        );

        Transforms.wrapNodes(
          editor,
          {
            type: format,
            children: [],
          },
          { at: newPath },
        );
      },
    });
  };

  return (
    <ButtonAction
      ariaLabel={ariaLabelText[format]}
      onClick={onToggle}
      Icon={iconButton[format]}
    />
  );
}

ToolbarBtnList.propTypes = {
  /**
   * Tipo di lista
   */
  format: PropTypes.oneOf([
    ELEMENT_TYPES.orderedList,
    ELEMENT_TYPES.unorderedList,
    ELEMENT_TYPES.alphabeticList,
  ]).isRequired,
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo blocck
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ToolbarBtnList;
