import React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

const Root = ({ children, ...props }) => (
  <TooltipPrimitive.Root className="tooltip" {...props}>
    {children}
  </TooltipPrimitive.Root>
);

const Content = ({ children, ...props }) => (
  <TooltipPrimitive.Content className="tooltip__content" {...props}>
    {children}
  </TooltipPrimitive.Content>
);

const Arrow = ({ children, ...props }) => (
  <TooltipPrimitive.Arrow className="tooltip__arrow" {...props} />
);

export const TooltipProvider = TooltipPrimitive.Provider;
export const Tooltip = Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;
export const TooltipArrow = Arrow;
