import React from "react";
import PropTypes from "prop-types";

function BlockModifiersTool({ converters = [], modifiers = [] }) {
  return (
    <div className="modifiers">
      {modifiers.length > 0 && (
        <div className="mb-2">
          <span className="font-bold">Modifica</span>
          <div className="flex space-x-1">{modifiers}</div>
        </div>
      )}
      {converters.length > 0 && (
        <div>
          <span className="font-bold whitespace-nowrap">Trasforma blocco</span>
          <div className="flex space-x-1">{converters}</div>
        </div>
      )}
    </div>
  );
}

BlockModifiersTool.propTypes = {
  converters: PropTypes.array,
  modifiers: PropTypes.array,
};

export default BlockModifiersTool;
