import React from "react";
import PropTypes from "prop-types";

import Button from "./ButtonBase";
import IconCmpt from "../Icon";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "../Tooltip";

/**
 * Componente per i pulsanti di azione (es: sposta, elimina, preview blocco per aggiunta/trasformazione)
 * @param {*} vedi propType
 */
function ButtonAction({ onClick, Icon, ariaLabel }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          square
          outline
          type="primary"
          onClick={onClick}
          ariaLabel={ariaLabel}
        >
          <IconCmpt label={ariaLabel} icon={Icon} size="30px" />
        </Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={2} side="top">
        {ariaLabel}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
}

ButtonAction.propTypes = {
  onClick: PropTypes.func,
  Icon: PropTypes.elementType,
  ariaLabel: PropTypes.string,
};

export default React.memo(ButtonAction);
