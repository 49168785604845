import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function withAuthetication(Component) {
  return (props) => {
    const location = useLocation();
    const { userInfo } = useAuth();

    return (
      <>
        {userInfo.email ? (
          <Component {...props} />
        ) : (
          <Navigate to="/login" state={{ from: location }} replace />
        )}
      </>
    );
  };
}

export default withAuthetication;
