import React from "react";
import { useParams, useOutletContext, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import Spinner from "../components/Spinner";
import Toolbar from "../layout/Toolbar";
import Editor from "../Editor";
import {
  arraysEqual,
  buildSummary,
  createEditorContentsFromToc,
  parseTocToSave,
  stringifyNode,
} from "../Editor/utils";
import headingTypes from "../Editor/Blocks/Heading/headingTypes";
import { VisibleHeadingContextProvider } from "../Editor/Blocks/Heading/useVisibleHeadingContext";
import { useAuth, withAuthetication } from "../Auth";
import { getChapter, saveChapter } from "../Api";
import { isMobile } from "../utils/deviceInfo";

function BookChapter() {
  const {
    userInfo: { canEdit },
  } = useAuth();
  const { summary } = useOutletContext(); // remote summary sections
  const [bookSummary, setBookSummary] = React.useState(summary); // local parsed copy kept updated
  const isMobileDevice = React.useMemo(() => isMobile(), []);

  const { chapter_id: chapterId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(!canEdit || isMobileDevice);
  const [furthestVisibleHeading, setFurthestVisibleHeading] = React.useState({
    id: "",
    disabled: false,
  });
  const [headingsAvailable, setHeadingsAvailable] = React.useState([]);
  const [contents, setContents] = React.useState([]);
  const [bookInfo, setBookInfo] = React.useState({});
  const [currentChapterIndex, setCurrentChapterIndex] = React.useState(-1);
  const headingsFilteredRef = React.useRef(null);

  React.useEffect(() => {
    setBookSummary(summary);
    const index = summary.findIndex(
      (section) => section.id.toString() === chapterId,
    );
    setCurrentChapterIndex(index);
  }, [summary]);

  React.useEffect(() => {
    boot().then().catch();
  }, [chapterId]);

  React.useEffect(() => {
    if (loading) return;

    if (location.hash) {
      document
        .getElementById(`${decodeURIComponent(location.hash.split("#")[1])}`)
        ?.scrollIntoView();
    } else {
      window.scrollTo(0, location.state?.scrollY || 0);
    }
  }, [location.state, location.hash, loading]);

  React.useEffect(() => {
    const headingsFiltered = contents.filter((c) => c.type === headingTypes);

    if (
      headingsFilteredRef.current !== null &&
      arraysEqual(headingsFiltered, headingsFilteredRef.current)
    ) {
      // Se non sono state fatte modifiche ai titoli non aggiorna il sommario
      return;
    } else if (currentChapterIndex === -1) {
      // Se è a -1 siamo nel render iniziale con content=[], teniamo a null in quanto vogliamo rigenerare il sommario
      headingsFilteredRef.current = null;
    } else {
      headingsFilteredRef.current = headingsFiltered;
    }

    const headings = headingsFiltered.map((h, id) => ({
      ...h,
      id,
      slug: stringifyNode(h, { separator: "-" }),
    }));

    setHeadingsAvailable(headings);
    const builtSummary = buildSummary(headings);
    setBookSummary((prev) => {
      if (currentChapterIndex === -1) return prev;

      const newSummary = [...prev];
      newSummary[currentChapterIndex].children = builtSummary;
      newSummary[currentChapterIndex].flatChildrenSlugs = [
        ...new Set(
          builtSummary.map((s) => [...s.flatChildrenSlugs, s.slug]).flat(),
        ),
      ]; // remove duplicates;
      return newSummary;
    });
  }, [contents, currentChapterIndex]);

  const boot = async () => {
    const index = summary.findIndex(
      (section) => section.id.toString() === chapterId,
    );
    setCurrentChapterIndex(index);
    setLoading(true);

    try {
      const chapter = await getChapter(chapterId);
      const content =
        chapter.content.length === 0
          ? createEditorContentsFromToc(chapter.toc.children)
          : chapter.content;
      setContents(content);
      setBookInfo({ id: chapter.id, book: chapter.book, order: chapter.order });
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onCurrentChapterTitleChange = (event) => {
    setBookSummary((prev) => {
      const newSummary = [...prev];
      if (currentChapterIndex === -1) return prev;

      newSummary[currentChapterIndex].title = event.target.value;
      return newSummary;
    });
  };

  const onSave = async () => {
    const data = {
      ...bookInfo,
      title: bookSummary[currentChapterIndex].title,
      toc: parseTocToSave(bookSummary[currentChapterIndex]),
      content: contents,
    };

    try {
      await toast.promise(
        saveChapter(chapterId, data),
        {
          loading: "Salvataggio...",
          success: "Capitolo salvato",
          error: "Errore nel salvataggio",
        },
        {
          style: { minWidth: "230px" },
        },
      );
    } catch (_) {
      // Errore gestito nel toast
    }
  };

  const visibleHeadingObject = React.useMemo(
    () => ({
      furthestVisibleHeading,
      setFurthestVisibleHeading,
      headingsAvailable,
    }),
    [furthestVisibleHeading, setFurthestVisibleHeading, headingsAvailable],
  );

  return (
    <VisibleHeadingContextProvider value={visibleHeadingObject}>
      <Toolbar
        canEdit={canEdit && !isMobileDevice}
        isReadOnly={readOnly}
        setReadOnly={setReadOnly}
        onCurrentChapterTitleChange={onCurrentChapterTitleChange}
        currentChapterIndex={currentChapterIndex}
        bookSummary={bookSummary}
        onSave={onSave}
      />
      {loading && <Spinner />}
      {!loading && (
        <main aria-label="Contenuto del libro" className="book-container">
          {error && <p className="text-center">Si è verificato un errore</p>}
          {!error && (
            <Editor
              readOnly={readOnly}
              setReadOnly={setReadOnly}
              contents={contents}
              setContents={setContents}
            />
          )}
        </main>
      )}
    </VisibleHeadingContextProvider>
  );
}

export default withAuthetication(BookChapter);
