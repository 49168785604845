import { Path, Transforms, Editor as SlateEditor } from "slate";

import { ELEMENT_TYPES } from "../../constants";

/**
 * Callback da chiamare se l'utente preme enter dentro un blockquote
 * @param {Object} editor editor di Slate.js
 */
const onEnterQuoteText = (editor) => {
  Transforms.select(
    editor,
    SlateEditor.end(
      editor,
      Path.next(Path.parent(Path.parent(editor.selection.focus.path))),
    ),
  );
};

/**
 * Callback da chiamare se l'utente preme enter detro un blocco quote-source
 * @param {Object} editor editor di Slate.js
 */
const onEnterQuoteSource = (editor) => {
  // Il primo parent è il blocco quote source, il secondo parent è il blocco quote
  const path = Path.parent(Path.parent(editor.selection.focus.path));
  const newPath = Path.next(path);

  Transforms.insertNodes(
    editor,
    { type: ELEMENT_TYPES.paragraph, children: [{ text: "" }] },
    { at: newPath },
  );

  Transforms.select(editor, {
    anchor: SlateEditor.start(editor, newPath),
    focus: SlateEditor.start(editor, newPath),
  });
};

export { onEnterQuoteText, onEnterQuoteSource };
