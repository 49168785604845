import React from "react";
import { Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import PropTypes from "prop-types";
import { useParams, useOutletContext } from "react-router-dom";
import toast from "react-hot-toast";

import { ELEMENT_TYPES } from "../../constants";
import { addBlockAndFocus, findPreceedingNodeByType } from "../../utils";
import { ButtonAction } from "../../../components/Button";

import logoMyLIM from "~/src/assets/myLIM-icon.png";
import { fetchMyLIMContentForSection } from "../../../Api";

/**
 * @typedef MyLIMContent
 * @type {object}
 * @property {object} posizione - un wrapper inutile (ma ce lo dobbiamo tenere)
 * @property {String} posizione.id - l'id dell'oggetto posizionabile
 * @property {String} posizione.titolo - il nome human-friendly dell'oggetto
 * @property {String} posizione.url - l'URL della risorsa
 * @property {String[]} posizione.sottotitoli - gli URL dei sottotitoli
 * @property {Number} posizione.sezione - l'id dela sezione MyLIM
 * @property {Number} posizione.pagina - il numero di pagina
 * @property {Number} posizione.x - la posizione x dell'oggetto
 * @property {Number} posizione.y - la posizione y dell'oggetto
 */

/**
 * @typedef ProcessedMyLIMContent
 * @type {object}
 * @property {String} id - l'id dell'oggetto posizionabile
 * @property {'audio'|'video'|undefined} type - il tipo di posizionabile
 * @property {String} titolo - il nome human-friendly dell'oggetto
 * @property {String} url - l'URL della risorsa
 * @property {String[]} sottotitoli - gli URL dei sottotitoli
 */

/**
 * Mappa da estensione file a tipo posizionabile
 */
const mapExtToType = {
  mp4: "video",
  mp3: "audio",
};

/**
 * Processa il risultato della chiamata API rimuovendo le porcherie del
 * posizionamento in pagina di MyLIM.
 *
 * @param {MyLIMContent[]} data - i dati ritornati dalla chiamata API
 * @returns {ProcessedMyLIMContent[]} - i dati nel formato usato in Slate
 */
const processAPIData = (data) =>
  data.map((el) => {
    const { id, titolo, url, sottotitoli } = el.posizione;
    const ext = /\.([^.]+)$/.exec(url)?.[1];
    const type = mapExtToType[ext];
    return {
      id,
      type,
      titolo,
      url,
      sottotitoli,
    };
  });

/**
 * Discirma l'inserimento all'inizio dell'editor.
 * L'inserimento di un blocco all'inizio dell'editor è indicato
 * con un path [-1].
 *
 * @param {Path} path - la path da controllare.
 * @returns true se la path passata indica un blocco da inserire all'inizio.
 */
const isInsertionAtBeginning = (path) => path?.[0] === -1;

const LogoMyLIM = () => (
  <img src={logoMyLIM} alt="myLIM logo" className="h-8 align-middle" />
);

function ToolbarBtnMyLIMContent({ path }) {
  const editor = useSlateStatic();

  const { isbn, chapter_id: chapterId } = useParams();
  const { summary = [] } = useOutletContext() || {}; // remote summary sections

  const getMyLimIdForChapter = () =>
    summary.find((s) => s.id.toString() === chapterId)?.mylim_id.toString();

  const onClick = async () => {
    const lastTitleNode = findPreceedingNodeByType(
      path,
      editor,
      ELEMENT_TYPES.heading,
    );

    let mylimSectionId = lastTitleNode?.mylim_id;
    if (!lastTitleNode && isInsertionAtBeginning(path)) {
      // Stiamo aggiungendo contenuti MyLIM legati all'intero capitolo
      mylimSectionId = getMyLimIdForChapter();
    }

    if (!mylimSectionId) {
      // Nessun titolo precendete o nessuna sezione MyLIM => mostriamo errore!
      const errMsg =
        lastTitleNode === null
          ? "Si può inserire un blocco myLIM solo all'interno di una sezione!"
          : "La sezione corrente non è associata a nessuna sezione myLIM";
      toast.error(errMsg);
      return;
    }

    let data;
    try {
      data = await fetchMyLIMContentForSection(isbn, mylimSectionId);
    } catch (err) {
      toast.error(
        "Non riesco a recuperare i contenuti MyLIM. Riprovare più tardi.",
      );
      return;
    }
    const contents = processAPIData(data);

    addBlockAndFocus({
      editor,
      path,
      insertNodes: (newPath) => {
        Transforms.insertNodes(
          editor,
          {
            type: ELEMENT_TYPES.mylimcontent,
            contents,
            children: [{ text: "" }],
          },
          {
            at: newPath,
          },
        );
      },
    });
  };

  return (
    <ButtonAction
      ariaLabel="Aggiungi contenuti sezione myLIM"
      onClick={onClick}
      Icon={LogoMyLIM}
    />
  );
}

ToolbarBtnMyLIMContent.propTypes = {
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo blocck
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ToolbarBtnMyLIMContent;
