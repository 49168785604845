import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import md5 from "md5";

import {
  readToken,
  saveToken,
  deleteToken,
  parseTokenData,
  getFreshToken,
  saveCredentials,
  deleteCredentials,
} from "./MylimAuthentication";
import { login as loginCall } from "./MylimAuthenticationService";
import { Api } from "../Api";
import Spinner from "../components/Spinner";

export const AuthContext = React.createContext(null);

/**
 * @typedef UserInfo
 * @type {object}
 * @property {string} email
 * @property {string} role
 * @property {boolean} canEdit
 */
const defaultUserInfo = {
  email: "",
  role: "Studente",
  canEdit: false,
};

/**
 * Fornisce il Provider del context delle info dell'utente e il wrapper per
 * le chiamate api che gestisce il refresh e la gestione del token JWT.
 */
function AuthProvider({ children }) {
  const [initTokenDone, setInitTokenDone] = React.useState(false);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const navigate = useNavigate();

  const initToken = async () => {
    // Leggiamo il token
    const tokenB = readToken();
    let token = parseTokenData(tokenB);
    token = await getFreshToken(token);
    if (!token) {
      // Dobbiamo loggarci
      setInitTokenDone(true);
      return;
    }
    // Autenticati !!!

    setUserInfo({
      email: token.data.email,
      role: token.data.loescher_roles[0] || "",
      canEdit: token.data.loescher_roles.includes("Redattore"),
    });

    // Refresh dell'interceptor axios con il token
    Api.interceptors.request.use(async (config) => {
      const jwt = await getFreshToken(token);
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `JWT ${jwt.token}`;

      if (token.token !== jwt.token) {
        await initToken();
      }
      return config;
    });
    setInitTokenDone(true);
  };

  // All'apertura dell'app
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    initToken().catch(console.error);
  }, []);

  const login = async (username, password, rememberMe) => {
    const hashedPswd = md5(password);
    let token;
    try {
      token = await loginCall(username, hashedPswd);
    } catch (err) {
      if (err.response?.status === 400) {
        throw Error(
          "Inserire utente e password prima di procedere con il login",
        );
      } else if (err.response?.status === 401) {
        throw Error("Impossibile accedere con le credenziali specificate.");
      }
      throw Error("Si è verificato un errore, riprovare più tardi.");
    }
    saveToken(token);
    if (rememberMe) {
      saveCredentials(username, hashedPswd);
    }
    setInitTokenDone(false);
    await initToken();
  };

  const logout = () => {
    deleteToken();
    deleteCredentials();
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ userInfo, login, logout }}>
      {initTokenDone ? children : <Spinner />}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return React.useContext(AuthContext);
}

export default AuthProvider;
