import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../Auth";

import logoLoescher from "~/src/assets/logo-loescher.png";
import logoDanna from "~/src/assets/logo-danna.png";
import logoISW from "~/src/assets/logo-isw.png";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const [errorMsg, setErrorMsg] = useState(null);
  const clearErrorMsg = () => setErrorMsg(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { login } = useAuth();

  const onLogin = async (evt) => {
    clearErrorMsg();
    evt.preventDefault();
    try {
      await login(username, password, rememberMe);
    } catch (err) {
      setErrorMsg(
        err?.message || "Si è verificato un errore, riprovare più tardi.",
      );
      return;
    }
    navigate(location?.state?.from || "/", { replace: true });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-between py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h1 className="mt-6 text-center text-2xl text-red-mylim">Login</h1>
          <a
            href="https://www.loescher.it/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-1/3 mx-auto my-8"
              src={logoISW}
              alt="Logo Imparo sul Web"
            />
          </a>
        </div>
        <form
          className="mt-8 mx-auto space-y-6 w-2/3 flex flex-col"
          action="#"
          onSubmit={onLogin}
        >
          {errorMsg && (
            <div className="alert alert-error shadow-lg">
              <div>
                <span>{errorMsg}</span>
              </div>
            </div>
          )}
          <div className="space-y-4">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Indirizzo email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Indirizzo email"
                value={username}
                onChange={(evt) => setUsername(evt.target.value)}
                className="w-full px-3 py-2 border border-gray-900 placeholder-gray-400 text-gray-900 rounded focus:outline-none focus:ring-red-mylim focus:border-red-mylim focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                placeholder="Password"
                className="w-full px-3 py-2 border border-gray-900 placeholder-gray-400 text-gray-900 rounded focus:outline-none focus:ring-red-mylim focus:border-red-mylim focus:z-10 sm:text-sm"
              />
            </div>
            <div className="form-control">
              <label
                htmlFor="remember-me"
                className="label cursor-pointer justify-center"
              >
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  defaultChecked={rememberMe}
                  onChange={() => setRememberMe((checked) => !checked)}
                  className="checkbox text-gray-900"
                />
                <span className="label-text ml-4 text-gray-900">Ricordami</span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="h-12 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-mylim focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-mylim"
          >
            Entra
          </button>
        </form>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex justify-around items-center max-w-md w-full my-6 h-20">
          <a
            className="w-auto h-full"
            href="https://www.loescher.it/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-auto h-full"
              src={logoLoescher}
              alt="Logo Loescher Editore"
            />
          </a>
          <a
            className="w-auto h-full"
            href="https://www.danna.it/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-auto h-full"
              src={logoDanna}
              alt="Logo Danna Editore"
            />
          </a>
        </div>
        <span className="text-neutral">
          <a
            href="https://www.loescher.it/privacy"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            ▸ Informazioni sulla privacy
          </a>
        </span>
      </div>
    </div>
  );
}

export default Login;
