/**
 * Funzioni per settare i parametri di customizzazione globale dello stile
 * definiti in `styles/themes.css`.
 *
 * Cosa può customizzare l'utente?
 * @typedef {
 *  'font-family'
 *  |'font-case'
 *  | 'font-size'
 *  | 'font-space'
 *  | 'theme'
 * } CustomizableParameter
 *
 *
 * @typedef { 'serif' | 'sans' | 'readable' } FontFamily
 * @typedef { 'normal' | 'uppercase' } FontCase
 * @typedef { '0' | '1' | '2' } FontSize
 * @typedef { 'normal' | 'double' } FontSpace
 * @typedef { 'light' | 'dark' | 'ivory' } Theme
 */

/**
 * Setta un attributo customizzabile dall'utente ad un valore.
 * @param {CustomizableParameter} attr - l'attributo da customizzare
 * @param {string} val - il valore dell'attributo
 * @param {HTMLElement} [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export const setThemeAttribute = (attr, val, element) => {
  const el = element || document.documentElement;
  el.setAttribute(`data-${attr}`, val);
};

/**
 * Setta la famiglia del carattere.
 * @param {FontFamily} val - il valore dell'attributo
 * @param {HTMLElement} [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export const setFontFamily = (val, element) => {
  setThemeAttribute("font-family", val, element);
};

/**
 * Setta il case del carattere.
 * @param {FontCase} val - il valore dell'attributo
 * @param {HTMLElement} [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export const setFontCase = (val, element) => {
  setThemeAttribute("font-case", val, element);
};
/**
 * Setta la dimensione del font.
 * @param {FontSize} val - il valore dell'attributo
 * @param {HTMLElement} [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export const setFontSize = (val, element) => {
  setThemeAttribute("font-size", val, element);
};

/**
 * Setta l'interlinea.
 * @param {FontSpace} val - il valore dell'attributo
 * @param {HTMLElement} [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export const setFontSpace = (val, element) => {
  setThemeAttribute("font-space", val, element);
};

/**
 * Setta il tema colori.
 * @param {Theme} val - il valore dell'attributo
 * @param {HTMLElement} [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export const setThemeColor = (val, element) => {
  setThemeAttribute("theme", val, element);
};

/**
 * Setta il tema a partire da una configurazione completa
 * @param {Object} conf configurazione del tema
 * @param {string} conf.fontCase
 * @param {string} conf.fontFamily
 * @param {string} conf.fontSize
 * @param {string} conf.fontSpace
 * @param {string} conf.themeColor
 */
export const setThemeFromConf = (conf) => {
  setFontCase(conf.fontCase);
  setFontFamily(conf.fontFamily);
  setFontSize(conf.fontSize);
  setFontSpace(conf.fontSpace);
  setThemeColor(conf.themeColor);
};
