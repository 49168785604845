import React from "react";
import PropTypes from "prop-types";

import { ToolbarBtnHeading } from "./Blocks/Heading";
import { ToolbarBtnHighlightBox } from "./Blocks/HighlightBox";
import { ToolbarBtnList } from "./Blocks/List";
import { ToolbarBtnParagraph } from "./Blocks/Paragraph";
import { ToolbarBtnQuote, ToolbarBtnQuoteParagraph } from "./Blocks/Quote";
import { ToolbarBtnImage } from "./Blocks/Image";
import ToolbarBtnMyLIMContent from "./Blocks/MyLIMContent/MyLIMContentButton";
import { ELEMENT_TYPES } from "./constants";
import {
  ToolbarBtnExample,
  ToolbarBtnExampleParagraph,
} from "./Blocks/Example";

/**
 * Componente che mostra un bottone sulla destra che modifica la proprietà background dell'element passato nei props.
 * @param {*} param
 */
function AddNewBlockBtn({ path, availableBlocks }) {
  return (
    <div className="add-block__dialog not-prose" aria-label="blocchi">
      {availableBlocks.map((type) => {
        switch (type) {
          case ELEMENT_TYPES.heading:
            return (
              <ToolbarBtnHeading key={ELEMENT_TYPES.heading} path={path} />
            );
          case ELEMENT_TYPES.highlightBox:
            return (
              <ToolbarBtnHighlightBox
                path={path}
                key={ELEMENT_TYPES.highlightBox}
              />
            );
          case ELEMENT_TYPES.alphabeticList:
            return (
              <ToolbarBtnList
                format={ELEMENT_TYPES.alphabeticList}
                key={ELEMENT_TYPES.alphabeticList}
                path={path}
              />
            );
          case ELEMENT_TYPES.orderedList:
            return (
              <ToolbarBtnList
                format={ELEMENT_TYPES.orderedList}
                key={ELEMENT_TYPES.orderedList}
                path={path}
              />
            );
          case ELEMENT_TYPES.unorderedList:
            return (
              <ToolbarBtnList
                format={ELEMENT_TYPES.unorderedList}
                key={ELEMENT_TYPES.unorderedList}
                path={path}
              />
            );
          case ELEMENT_TYPES.paragraph:
            return (
              <ToolbarBtnParagraph key={ELEMENT_TYPES.paragraph} path={path} />
            );
          case ELEMENT_TYPES.quote:
            return <ToolbarBtnQuote key={ELEMENT_TYPES.quote} path={path} />;
          case ELEMENT_TYPES.image:
            return <ToolbarBtnImage key={ELEMENT_TYPES.image} path={path} />;
          case ELEMENT_TYPES.mylimcontent:
            return (
              <ToolbarBtnMyLIMContent
                key={ELEMENT_TYPES.mylimcontent}
                path={path}
              />
            );
          case ELEMENT_TYPES.quoteParagraph:
            return (
              <ToolbarBtnQuoteParagraph
                key={ELEMENT_TYPES.quoteParagraph}
                path={path}
              />
            );
          case ELEMENT_TYPES.example:
            return (
              <ToolbarBtnExample key={ELEMENT_TYPES.example} path={path} />
            );
          case ELEMENT_TYPES.exampleParagraph:
            return (
              <ToolbarBtnExampleParagraph
                key={ELEMENT_TYPES.exampleParagraph}
                path={path}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

AddNewBlockBtn.propTypes = {
  /**
   * Path dell'elemento dopo il quale si vuole aggiunge il nuovo blocck
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * Element di Slate.js
   */
  availableBlocks: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(AddNewBlockBtn);
