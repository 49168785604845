import { Path, Transforms, Editor as SlateEditor } from "slate";

import { ELEMENT_TYPES } from "../../constants";

/**
 * Callback da chiamare se l'utente preme enter dentro un blocco esempio
 * @param {Object} editor editor di Slate.js
 */
const onEnterExampleText = (editor) => {
  const path = Path.parent(Path.parent(editor.selection.focus.path));
  const newPath = Path.next(path);

  Transforms.insertNodes(
    editor,
    { type: ELEMENT_TYPES.paragraph, children: [{ text: "" }] },
    { at: newPath },
  );

  Transforms.select(editor, {
    anchor: SlateEditor.start(editor, newPath),
    focus: SlateEditor.start(editor, newPath),
  });
};

export default onEnterExampleText;
