import React from "react";

export const VisibleHeadingContext = React.createContext({
  headingsAvailable: [],
  furthestVisibleHeading: { id: "", disabled: false },
  setFurthestVisibleHeading: () => {},
});

export const VisibleHeadingContextProvider = ({ children, value }) => (
  <VisibleHeadingContext.Provider value={value}>
    {children}
  </VisibleHeadingContext.Provider>
);

function useVisibleHeadings() {
  const {
    headingsAvailable,
    furthestVisibleHeading,
    setFurthestVisibleHeading,
  } = React.useContext(VisibleHeadingContext);

  const resetHeading = () =>
    setFurthestVisibleHeading({ id: "", disabled: true });

  const addHeading = (headingToAdd) => {
    if (headingToAdd.rect.top >= 10) {
      // 10 è una tolleranza trovata empiricamente che sembra funzionare
      setFurthestVisibleHeading({ id: headingToAdd.id, disabled: false });
    }
  };

  const removeHeading = (headingToRemove) => {
    if (headingToRemove.rect.bottom === window.innerHeight) {
      const index = headingsAvailable.findIndex(
        (h) => h.slug === headingToRemove.id,
      );
      if (index > 0)
        setFurthestVisibleHeading({
          id: headingsAvailable[index - 1].slug,
          disabled: false,
        });
    }
  };

  return {
    furthestVisibleHeading,
    resetHeading,
    removeHeading,
    addHeading,
  };
}

export default useVisibleHeadings;
