import React from "react";
import PropTypes from "prop-types";
import { useSlateStatic } from "slate-react";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";
import useModifiers from "../../useModifiersContext";
import { ButtonConverterToParagraph } from "~/src/Editor/BlockConverter";

/**
 * Wrapper dell'elemento quote, al suo interno contiene QuoteText e QuoteSource
 * @param {*} param
 */
function Quote({ element, attributes, children }) {
  const editor = useSlateStatic();

  const converterBtns = [
    <ButtonConverterToParagraph
      key="changeToParagraph"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ converterBtns, element });

  return (
    <figure className="quote" {...attributes}>
      {children}
    </figure>
  );
}

Quote.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Children generati da Slate.js da inserire all'elemento
   */
  children: PropTypes.array,
};

/**
 * Componente che wrappa i paragrafi della citazione
 * @param {} param
 */
function QuoteText({ attributes, children }) {
  return <blockquote {...attributes}>{children}</blockquote>;
}

QuoteText.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Children generati da Slate.js da inserire all'elemento
   */
  children: PropTypes.array,
};

/**
 * Paragrafi contenuti all'interno della citazione
 * @param {*} param
 */
function QuoteParagraph({ attributes, children }) {
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  return <p {...attributes}>{children}</p>;
}

QuoteParagraph.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Children generati da Slate.js da inserire all'elemento
   */
  children: PropTypes.array,
};

/**
 * Componente che contiene la fonte della citazione
 * @param {} param
 */
function QuoteSource({ attributes, children }) {
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  return <figcaption {...attributes}>{children}</figcaption>;
}

QuoteSource.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Children generati da Slate.js da inserire all'elemento
   */
  children: PropTypes.array,
};

export { Quote, QuoteText, QuoteSource, QuoteParagraph };
