import React from "react";
import PropTypes from "prop-types";
import { useSlateStatic } from "slate-react";

import {
  ButtonConverterToTitle,
  ButtonConverterToParagraph,
  ButtonConverterToOrderedList,
  ButtonConverterToBulletedList,
  ButtonWrapHighlightBox,
  ButtonConverterToAlphabeticList,
  ButtonConverterToExample,
} from "~/src/Editor/BlockConverter";
import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "~/src/Editor/Toolbar/";
import useModifiers from "../../useModifiersContext";

/**
 * Componente che renderizza una lista non ordinata
 * @param {*} param vedi PropTypes
 */
export function UnorderedList({ attributes, children, element }) {
  const editor = useSlateStatic();
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  const converterBtns = [
    <ButtonConverterToTitle
      key="changeToTitle"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToParagraph
      key="changeToParagraph"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToOrderedList
      key="changeToOrderedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToAlphabeticList
      key="changeToAlphabeticalList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToExample
      key="changeToExample"
      editor={editor}
      element={element}
    />,
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ converterBtns, element });

  return (
    <ul className="pl-4" {...attributes}>
      {children}
    </ul>
  );
}

UnorderedList.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any.isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

/**
 * Componente che renderizza una lista ordinata
 * @param {*} param vedi PropTypes
 */
export function OrderedList({ attributes, children, element }) {
  const editor = useSlateStatic();
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  const converterBtns = [
    <ButtonConverterToTitle
      key="changeToTitle"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToParagraph
      key="changeToParagraph"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToBulletedList
      key="changeToBulletedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToAlphabeticList
      key="changeToAlphabeticalList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToExample
      key="changeToExample"
      editor={editor}
      element={element}
    />,
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ converterBtns, element });

  return (
    <ol className="pl-4" {...attributes}>
      {children}
    </ol>
  );
}

OrderedList.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any.isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

/**
 * Componente che renderizza una lista alfabetica
 * @param {*} param vedi PropTypes
 */
export function AlphabeticList({ attributes, children, element }) {
  const editor = useSlateStatic();
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  const converterBtns = [
    <ButtonConverterToTitle
      key="changeToTitle"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToParagraph
      key="changeToParagraph"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToBulletedList
      key="changeToBulletedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToOrderedList
      key="changeToOrderedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToExample
      key="changeToExample"
      editor={editor}
      element={element}
    />,
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ converterBtns, element });

  return (
    <ol type="a" className="pl-4" {...attributes}>
      {children}
    </ol>
  );
}

AlphabeticList.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any.isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

/**
 * Ritorna un element 'li'
 * @param {*} param Vedi PropTypes
 */
export function ListItem({ attributes, children }) {
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  return <li {...attributes}>{children}</li>;
}

ListItem.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any.isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};
