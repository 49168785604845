import React from "react";

export const BlockContext = React.createContext({
  setIsHighlighted: () => {},
});

/**
 * Hook che legge il context e ritorna la funzione per settare il blocco come Highlighted. Questa viene chiamata quando
 * il blocco deve avere lo stesso stile di quando si trova in hover. Al momento viene usata DeleteBlock.
 */
function useBlockContext() {
  const { setIsHighlighted } = React.useContext(BlockContext);

  return { setIsHighlighted };
}

export default useBlockContext;
