import React from "react";
import PropTypes from "prop-types";

import { Paragraph } from "./Blocks/Paragraph";
import { Heading } from "./Blocks/Heading";
import { HighlightBox } from "./Blocks/HighlightBox";
import { Image } from "./Blocks/Image";
import { Glossario } from "./InlineBlocks/Glossario";
import {
  ListItem,
  OrderedList,
  UnorderedList,
  AlphabeticList,
} from "./Blocks/List";
import Block from "./Block";
import { ELEMENT_TYPES } from "./constants";
import { Quote, QuoteParagraph, QuoteSource, QuoteText } from "./Blocks/Quote";
import { BlockLink } from "./InlineBlocks/BlockLink";
import MyLIMContent from "./Blocks/MyLIMContent/MyLIMContent";
import { Example, ExampleParagraph } from "./Blocks/Example";

Element.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Children generati da Slate.js da inserire all'elemento
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * Proprietà generata da Slate.js che contiene informazioni sull'elemento
   */
  element: PropTypes.shape({
    /**
     * Il tipo di blocco
     */
    type: PropTypes.string.isRequired,
  }),
};

/**
 * Data in ingresso un Elemento con un tipo, genera un blocco da inserire
 * nell'editor.
 * @param {*} param vedi PropTypes
 * @returns Blocco per l'editor generato in base al tipo
 */
function Element(props) {
  const { attributes, children, element } = props;
  let component = null;

  switch (element.type) {
    case ELEMENT_TYPES.heading:
      component = (
        <Heading attributes={attributes} element={element}>
          {children}
        </Heading>
      );
      break;

    case ELEMENT_TYPES.highlightBox:
      component = (
        <HighlightBox attributes={attributes} element={element}>
          {children}
        </HighlightBox>
      );
      break;

    case ELEMENT_TYPES.image:
      component = (
        <Image attributes={attributes} element={element}>
          {children}
        </Image>
      );
      break;

    case ELEMENT_TYPES.glossario:
      component = (
        <Glossario attributes={attributes} element={element}>
          {children}
        </Glossario>
      );
      break;

    case ELEMENT_TYPES.unorderedList:
      component = (
        <UnorderedList attributes={attributes} element={element}>
          {children}
        </UnorderedList>
      );
      break;

    case ELEMENT_TYPES.orderedList:
      component = (
        <OrderedList attributes={attributes} element={element}>
          {children}
        </OrderedList>
      );
      break;

    case ELEMENT_TYPES.alphabeticList:
      component = (
        <AlphabeticList attributes={attributes} element={element}>
          {children}
        </AlphabeticList>
      );
      break;

    case ELEMENT_TYPES.listItem:
      component = <ListItem attributes={attributes}>{children}</ListItem>;
      break;

    case ELEMENT_TYPES.quote:
      component = (
        <Quote element={element} attributes={attributes}>
          {children}
        </Quote>
      );
      break;

    case ELEMENT_TYPES.quoteText:
      component = <QuoteText attributes={attributes}>{children}</QuoteText>;
      break;

    case ELEMENT_TYPES.quoteSource:
      component = <QuoteSource attributes={attributes}>{children}</QuoteSource>;
      break;

    case ELEMENT_TYPES.quoteParagraph:
      component = (
        <QuoteParagraph attributes={attributes}>{children}</QuoteParagraph>
      );
      break;

    case ELEMENT_TYPES.link:
      component = (
        <BlockLink attributes={attributes} element={element}>
          {children}
        </BlockLink>
      );
      break;

    case ELEMENT_TYPES.mylimcontent:
      component = <MyLIMContent {...props} />;
      break;

    case ELEMENT_TYPES.example:
      component = <Example {...props} />;
      break;

    case ELEMENT_TYPES.exampleParagraph:
      component = <ExampleParagraph {...props} />;
      break;

    default:
      component = (
        <Paragraph attributes={attributes} element={element}>
          {children}
        </Paragraph>
      );
  }

  return <Block element={element}>{component}</Block>;
}

export default Element;
