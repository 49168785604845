import React from "react";
import { Transforms } from "slate";
import { useReadOnly, ReactEditor, useSlateStatic } from "slate-react";
import * as Popover from "@radix-ui/react-popover";
import PropTypes from "prop-types";

/**
 * Componente che renderizza un fumetto sopra a una o più parole per indicare un link associato ad essi.
 * @param {*} param
 */
function BlockLink({ attributes, element, children }) {
  const editor = useSlateStatic();
  const isReadOnly = useReadOnly();

  const onChange = (e) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { url: e.target.value }, { at: path });
  };

  const fixUrl = (open) => {
    if (!open) {
      const isValid = element.url.match(/https?:\/\//);

      if (!isValid) {
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(
          editor,
          { url: `https://${element.url}` },
          { at: path },
        );
      }
    }
  };

  if (isReadOnly) {
    return (
      <a
        {...attributes}
        href={element.url}
        target="_blank"
        rel="noreferrer noopener"
      >
        {children}
      </a>
    );
  }

  return (
    <Popover.Root onOpenChange={fixUrl}>
      <Popover.Trigger>
        <span {...attributes} className="underline">
          {children}
        </span>
      </Popover.Trigger>
      <Popover.Content
        side="top"
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="z-editor-dialog"
      >
        <input
          aria-label="url blocco link"
          placeholder="https://example.com"
          className="input input-primary input-bordered"
          type="text"
          value={element.url}
          onChange={onChange}
        />
      </Popover.Content>
    </Popover.Root>
  );
}

BlockLink.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
  element: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.any.isRequired,
};

export default BlockLink;
