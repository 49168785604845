import React from "react";

/**
 * Il contesto React a cui i blocchi accederanno tramite l'hook `useModifiers`.
 */
export const ModifiersContext = React.createContext({
  modifierBtns: [],
  converterBtns: [],
  setButtons: () => {},
});

/**
 * Hook per gestire i modifiers di un blocco.
 * L'hook viene chiamato all'interno dei blocchi per definire se e quali bottoni
 * saranno disponibili per la modifica del blocco stesso.
 *
 * Esempio:
 * ```
 *   useModifiers([<ModifierBtn props={...} />, <ModifierBtn props={...} />], elementSlate)
 * ```
 * oppure ignorando il parametro per non visualizzare i modifier
 * ```
 *   useModifiers()
 * ```
 * oppure non scrivendo `useModifiers`
 *
 * @param {Array<ReactElement> | undefined} btns - array con i bottoni da visualizzare
 * @param {Object | undefined} element - element di Slate.js
 */
const useModifiers = ({
  modifierBtns = [],
  converterBtns = [],
  element = null,
}) => {
  const { setButtons } = React.useContext(ModifiersContext);

  React.useEffect(() => {
    setButtons({ modifierBtns, converterBtns });
  }, [element]);
};

export default useModifiers;
