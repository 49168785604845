import React from "react";
import PropTypes from "prop-types";

import ButtonBase from "../../../components/Button";

export const ModifierButton = ({
  children,
  onClick,
  ariaLabel,
  isActive = false,
  isTogglable = false,
}) => (
  <ButtonBase
    outline={!isActive}
    size="sm"
    square
    type="primary"
    onClick={onClick}
    ariaLabel={ariaLabel}
    classes="modifier-btn"
    disabled={!isTogglable && isActive}
  >
    {children}
  </ButtonBase>
);

ModifierButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isTogglable: PropTypes.bool,
};
