import React, { useState } from "react";
import { Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import PropTypes from "prop-types";
import { MdDelete } from "react-icons/md";

import Modal from "../layout/Modal";
import useBlockContext from "./useBlockContext";
import { ButtonAction } from "../components/Button";
import { ELEMENT_TYPES } from "./constants";

/**
 * Mappa dei warning da mostrare nella modale di cancellazione per ogni blocco.
 */
export const DELETE_WARNINGS = {
  [ELEMENT_TYPES.heading]:
    "ATTENZIONE! Cancellando il blocco cancellerai anche tutti i materiali associati su mylim!",
};

/**
 * Renderizza un bottone che apre una modale per cancellare il blocco
 * @param {*} param
 */
function DeleteBlock({ path, type }) {
  const editor = useSlateStatic();
  const { setIsHighlighted } = useBlockContext();
  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open) => {
    setIsOpen(open);
    setIsHighlighted(open);
  };

  const onDeleteClick = () => {
    onOpenChange(false);
    Transforms.removeNodes(editor, { at: path });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        text="Sei sicuro di volere cancellare il blocco?"
        warningText={DELETE_WARNINGS[type]}
        dangerText="Conferma"
        primaryText="Annulla"
        onDangerClick={onDeleteClick}
        onPrimaryClick={() => onOpenChange(false)}
      />

      <ButtonAction
        ariaLabel="Cancella blocco"
        onClick={() => onOpenChange(!isOpen)}
        Icon={MdDelete}
      />
    </>
  );
}

DeleteBlock.propTypes = {
  /**
   * Path Slate dell'elemento da cancellare
   */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * Tipo del blocco
   */
  type: PropTypes.oneOf(Object.keys(ELEMENT_TYPES).map((k) => ELEMENT_TYPES[k]))
    .isRequired,
};

export default DeleteBlock;
