import React from "react";
import PropTypes from "prop-types";

import { FadeAnimation } from "../../utils/animations";

Toolbar.propTypes = {
  buttons: PropTypes.array,
};

function Toolbar({ buttons = [] }) {
  return (
    <FadeAnimation in={!!buttons.length}>
      <div className="editor-toolbar" data-testid="editor-toolbar">
        {buttons.map((btn) => btn)}
      </div>
    </FadeAnimation>
  );
}

export default Toolbar;
