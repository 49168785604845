import React from "react";
import PropTypes from "prop-types";
import { ReactEditor } from "slate-react";
import {
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdTexture,
  MdLocalParking,
  MdTitle,
  MdChecklistRtl,
  MdLightbulb,
} from "react-icons/md";

import {
  changeToBulletedList,
  changeToOrderedList,
  changeToParagraph,
  changeToQuote,
  changeToTitle,
  removeHighlightBox,
  wrapHighlightBox,
  changeToAlphabeticList,
  changeToExample,
} from "./BlockConverter";
import { ButtonAction } from "../../components/Button";

/**
 * Componente bottone per cambiare il blocco in paragrafo
 * @param {*} param
 */
export const ButtonConverterToParagraph = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in paragrafo"
    onClick={() => changeToParagraph(editor, element)}
    Icon={MdLocalParking}
  />
);

ButtonConverterToParagraph.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per cambiare il blocco in bulleted list
 * @param {*} param
 */
export const ButtonConverterToBulletedList = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in lista non ordinata"
    onClick={() => changeToBulletedList(editor, element)}
    Icon={MdFormatListBulleted}
  />
);

ButtonConverterToBulletedList.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per cambiare il blocco in ordered list
 * @param {*} param
 */
export const ButtonConverterToOrderedList = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in lista ordinata"
    onClick={() => changeToOrderedList(editor, element)}
    Icon={MdFormatListNumbered}
  />
);

ButtonConverterToOrderedList.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per cambiare il blocco in lista alfabetica ordinata
 * @param {*} param
 */
export const ButtonConverterToAlphabeticList = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in lista alfabetica"
    onClick={() => changeToAlphabeticList(editor, element)}
    Icon={MdChecklistRtl}
  />
);

ButtonConverterToAlphabeticList.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per cambiare il blocco in title
 * @param {*} param
 */
export const ButtonConverterToTitle = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in intestazione"
    onClick={() => changeToTitle(editor, element)}
    Icon={MdTitle}
  />
);

ButtonConverterToTitle.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per rimuovere il focux box dal blocco
 * @param {*} param
 */
export const ButtonRemoveHighlightBox = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Rimuovi blocco in evidenza"
    onClick={() => removeHighlightBox(editor, element)}
    Icon={MdTexture}
  />
);

ButtonRemoveHighlightBox.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per wrappare blocco in highlight box
 * @param {*} param
 */
export const ButtonWrapHighlightBox = ({ editor, element }) => {
  const path = ReactEditor.findPath(editor, element);

  if (path.length > 1) {
    return null;
  } else {
    return (
      <ButtonAction
        ariaLabel="Trasforma in blocco in evidenza"
        onClick={() => wrapHighlightBox(editor, path)}
        Icon={MdTexture}
      />
    );
  }
};

ButtonWrapHighlightBox.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per transformare un testo in quote
 * @param {*} param
 */
export const ButtonConverterToQuote = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in citazione"
    onClick={() => changeToQuote(editor, element)}
    Icon={MdFormatQuote}
  />
);

ButtonConverterToQuote.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};

/**
 * Componente bottone per transformare un testo in esempio
 * @param {*} param
 */
export const ButtonConverterToExample = ({ editor, element }) => (
  <ButtonAction
    ariaLabel="Trasforma in esempio"
    onClick={() => changeToExample(editor, element)}
    Icon={MdLightbulb}
  />
);

ButtonConverterToExample.propTypes = {
  // Editor di Slate.js
  editor: PropTypes.object.isRequired,
  // Element di Slate.js
  element: PropTypes.any.isRequired,
};
