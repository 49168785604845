import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "~/src/components/Button";

import Portal from "~/src/components/Portal/";

/**
 * Renderizza una modale
 * @param {*} param
 */
function Modal({
  isOpen,
  text,
  dangerText,
  primaryText,
  onDangerClick,
  onPrimaryClick,
  warningText = null,
}) {
  return (
    <Portal>
      <div
        className={classNames("modal", { "modal-open": isOpen })}
        role="alertdialog"
        aria-labelledby="dialog_label"
      >
        <div className="modal-box" contentEditable={false} role="document">
          <p id="dialog_label">{text}</p>
          {warningText && <p className="mt-4 text-warning">{warningText}</p>}
          <div className="modal-action">
            <Button type="warning" onClick={onDangerClick} text={dangerText} />
            <Button onClick={onPrimaryClick} text={primaryText} />
          </div>
        </div>
      </div>
    </Portal>
  );
}

Modal.propTypes = {
  /**
   * Se la modal è visibile
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Testo della modale
   */
  text: PropTypes.string.isRequired,
  /**
   * Testo del bottone danger
   */
  dangerText: PropTypes.string.isRequired,
  /**
   * Testo del bottone primario
   */
  primaryText: PropTypes.string.isRequired,
  /**
   * Callback se l'utente preme l'azione primaria
   */
  onPrimaryClick: PropTypes.func.isRequired,
  /**
   * Callback quando l'utente preme l'azione danger
   */
  onDangerClick: PropTypes.func.isRequired,
  /**
   * Eventuale messaggio di warning
   */
  warningText: PropTypes.string,
};

export default Modal;
