import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "~/src/components/Button";

import Portal from "~/src/components/Portal/";

/**
 * Renderizza una modale
 * @param {*} param
 */
function ModalInput({
  isOpen,
  text,
  value,
  onChange,
  placeholder,
  primaryText,
  onPrimaryClick,
  dangerText,
  onDangerClick,
  inputName,
  inputId,
  warningText = null,
}) {
  return (
    <Portal>
      <div
        className={classNames("modal", { "modal-open": isOpen })}
        role="alertdialog"
        aria-labelledby="dialog_label"
      >
        <div className="modal-box" contentEditable={false} role="document">
          <p id="dialog_label" className="font-bold text-lg mb-4">
            {text}
          </p>
          <label htmlFor={inputId} className="sr-only">
            {inputName}
          </label>
          <input
            type="text"
            value={value}
            id={inputId}
            onChange={onChange}
            className="input input-bordered w-full max-w-xs"
            placeholder={placeholder}
          />
          {warningText && <p className="mt-4 text-warning">{warningText}</p>}
          <div className="modal-action">
            <Button type="warning" onClick={onDangerClick} text={dangerText} />
            <Button onClick={onPrimaryClick} text={primaryText} />
          </div>
        </div>
      </div>
    </Portal>
  );
}

ModalInput.propTypes = {
  /**
   * Se la modal è visibile
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Testo della modale
   */
  text: PropTypes.string.isRequired,
  /**
  /**
  * Testo del bottone primario
  */
  primaryText: PropTypes.string.isRequired,
  /**
   * Callback per l'azione primaria
   */
  onPrimaryClick: PropTypes.func.isRequired,
  /**
   * Testo del bottone con azione distruttiva
   */
  dangerText: PropTypes.string.isRequired,
  /**
   * Callback per l'azione distruttiva
   */
  onDangerClick: PropTypes.func.isRequired,

  /**
   * Gestione dell'input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,

  /**
   * Label accessibile per l'input
   */
  inputName: PropTypes.string.isRequired,
  /**
   * Id da associare all'input (globalmente univoco)
   */
  inputId: PropTypes.string.isRequired,
  /**
   * Eventuale messaggio di warning
   */
  warningText: PropTypes.string,
};

export default ModalInput;
